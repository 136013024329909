<template lang="pug">
  .agency-branding-preview
    AgencyBrandingEdit
    v-card(outlined shaped)
      .d-flex.align-start.justify-space-between
        em.label.ma-2 Branding Preview
        v-spacer
        v-btn(:disabled="!userData || !userData.isOwner", depressed large tile @click="showEditAgencyBranding = true")
          v-icon.mr-1 mdi-playlist-edit
          | Edit
      .pa-5
        v-img(:src="branding.logo" max-width="200" contain).mx-auto
        h2(:style="{color: branding.colors.primary}").my-4 {{branding.name}}
        p {{branding.description}}
        p
          a(:href="branding.url" target="_blank" rel="noopener").text-decoration-none
            v-icon.mr-1 mdi-link
            | {{branding.url}}
</template>

<script>
import { get, sync } from "vuex-pathify";
import AgencyBrandingEdit from "@/components/CRM/Account/AgencyBrandingEdit.vue";
export default {
  name: "AgencyBrandingPreview",
  components: { AgencyBrandingEdit },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...get({
      userData: "User/userData",
      branding: "User/agencyData@branding"
    }),
    ...sync({
      showEditAgencyBranding: "CrmNav/dialogs@showEditAgencyBranding"
    })
  },
  methods: {}
};
</script>
