<template lang="pug">
      v-card.lead-card(flat shaped color="grey lighten-4").d-flex.flex-column.justify-space-between
        v-sheet(:color="leadColor" height="20px" width="100%")
        v-card-text
          v-sheet(color="transparent", min-height="130")
            .text-right
                LeadStageIndicator(v-if="lead.basicInfo.stage", :stage="lead.basicInfo.stage")
            v-spacer
            .d-flex.justify-start.align-center.text-h6
                b.firstName {{lead.basicInfo.firstName}}
                .lastName.ml-1 {{lead.basicInfo.lastName}}
                .nickname(v-if="lead.advancedInfo.nickname").ml-1 ({{lead.advancedInfo.nickname}})
            .d-flex.flex-column.mt-3
              .email(v-if="lead.basicInfo.email")
                v-icon.mr-1(small) mdi-email
                a(:href="'mailto:' + lead.basicInfo.email") {{lead.basicInfo.email}}
            
              .phone(v-if="lead.basicInfo.phonePrimary")
                v-icon.mr-1(small) mdi-phone
                a(:href="'tel:' + lead.basicInfo.phonePrimary") {{lead.basicInfo.phonePrimary}}

              .lastActive.mt-4.body-2 Last active {{lead.lastActive.toDate() | asAgo}}
        
        v-card-actions.pa-0
          v-row(dense justify="space-between" align="center" no-gutters)
            v-col(cols="12", lg="6")
              v-btn(tile block text color="primary lighten-1" @click.prevent="sendProperties") Send Properties
            v-col(cols="12", lg="6")
              v-btn(:color="leadColor" depressed tile block @click="showLeadDetails")
                v-icon.mr-2 mdi-dots-horizontal
                | Details
    
</template>

<script>
import LeadStageIndicator from "@/components/CRM/Leads/Partials/LeadStageIndicator.vue";
import { get, sync, call } from "vuex-pathify";
export default {
  name: "LeadCardShort",
  components: { LeadStageIndicator },
  props: {
    lead: { type: Object, required: true }
  },
  data() {
    return {};
  },
  mounted() {
    this.init();
  },
  computed: {
    ...get({ stageOptions: "Leads/stageOptions" }),
    ...sync({ activeLeadId: "Leads/activeLeadId" }),
    leadColor() {
      return this.stageOptions.find(
        stage => stage.value === this.lead.basicInfo.stage
      ).color;
    }
  },
  methods: {
    ...call({ bindActiveLead: "Leads/bindActiveLead" }),
    init() {},
    async sendProperties() {
      await this.bindActiveLead({ leadId: this.lead.id });
      this.$router.push({ path: "/crm/CreateProposal" });
    },
    async showLeadDetails() {
      await this.bindActiveLead({ leadId: this.lead.id });
      this.$router.push({ path: `/crm/LeadDetails?id=${this.lead.id}` });
    }
  }
};
</script>
