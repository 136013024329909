<template lang="pug">
    .maker-section--add-notes
      TabCard(title="Add your notes", :subtitle="'You pasted ' + links.length + ' valid links to share'", showNextButton, showBackButton, :buttonError="buttonError", @click:next="$emit('click:next')", @click:back="$emit('click:back')")
        v-card.link-notes(outlined shaped v-for="link, idx in links", :key="link").my-6.my-md-8.grey.lighten-4.pa-3
          v-row(dense align="start").mb-3
            v-col(cols="1")
              .text-center.text-h5.link-num.primary--text.text--disabled.font-weight-bold {{idx+1}}
            v-col(cols="11")
              a(:href="link" target="_blank", rel="noopener").body-2.link-for-notes
                b {{link}}
                                
          v-textarea(v-model="notes[idx]", rows="2" outlined hide-details placeholder="(optional) Type notes about this link")
</template>

<script>
import { get, sync } from "vuex-pathify";
import TabCard from "@/components/CRM/Proposals/Maker/TabCard";
export default {
  name: "MakerSectionAddNotes",
  components: { TabCard },
  data() {
    return {};
  },
  computed: {
    ...get({
      links: "ProposalMaker/links",
      buttonError: "ProposalMaker/buttonError"
    }),
    ...sync({ notes: "ProposalMaker/notes" })
  }
};
</script>

<style lang="scss" scoped>
.link-for-notes {
  opacity: 0.5;
  text-decoration: none;
  font-style: oblique;
}
.link-for-notes:hover {
  transition: 0.3s all ease-in;
  opacity: 1;
}
</style>
