<template lang="pug">
    .events
        h2.text-center.display-1.mb-4 Proposal Analytics
        
        v-data-table(v-if="events && counts && events.length > 0 && counts.length > 0", :items="events", group-by='value', :headers="headers", sortBy="created" sortDesc hide-default-header :items-per-page="25")
          template(v-slot:group.header="{ items }")
            td(colspan="3", class="text-start")
              span
                b {{calcViews(items[0].property ? items[0].value : items[0].source)}} Views: 
              span(:title="prettySource(items)") {{prettySource(items)}}
          template(v-slot:item.type="{ item }")
            v-icon(v-if="item.type==='ENTER_PROPOSAL'") mdi-source-commit-start
            v-icon(v-else-if="item.type==='ENTER_PROPERTY'") mdi-arrow-right-bold
            v-icon(v-else-if="item.type==='LEAVE_PROPERTY'") mdi-arrow-top-left
            v-icon(v-else-if="item.type==='LEAVE_PROPOSAL'") mdi-exit-to-app
            v-icon(v-else-if="item.type==='VIEW_IMAGE'") mdi-image-search-outline
            span.ml-1.text-capitalize {{item.type.toLowerCase().split("_").join(" ")}}
          template(v-slot:item.created="{ item }")
            .label(v-if="$vuetify.breakpoint.smAndDown") Date
            span {{prettyDate(item.created)}}
          template(v-slot:item.url="{ item }")
            template(v-if="item.property || item.type === 'VIEW_IMAGE'")
              .label(v-if="$vuetify.breakpoint.smAndDown") Link
              v-btn(icon :href="item.property ? item.source : item.value" target="_blank" rel="noopener")
                v-icon mdi-link

        //- pre.debug {{events}}
</template>

<script>
import { get } from "vuex-pathify";
export default {
  name: "Counts",
  data() {
    return {
      headers: [
        {
          text: "Type",
          value: "type",
          class: "header",
          width: 200,
          sortable: false
        },

        {
          text: "Date",
          value: "created",
          class: "header",
          sortable: false,
          width: 200
        },
        {
          text: "Link",
          value: "url",
          class: "header",
          sortable: false,
          width: 50
        }
      ]
    };
  },
  computed: {
    ...get({
      events: "Analytics/events",
      counts: "Analytics/counts"
    })
  },
  methods: {
    prettyDate(dt) {
      const options = {
        // weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      };

      return dt.toLocaleDateString("en-Us", options);
    },
    prettySource(items) {
      if (!items || items.length < 1) return "?";
      else if (!items[0].property || !items[0].property.sourceName)
        return "Overall Proposal";
      else {
        const { sourceName, title } = items[0].property;
        return "(" + sourceName + ") " + title;
      }
    },
    calcViews(url) {
      const foundCount = this.counts.find(count => count.url === url);
      return foundCount ? foundCount.views : "?";
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .header {
  background: rgba(223, 223, 223, 1);
}

.label {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

.min-width {
  width: 500px;
}

::v-deep tr.v-row-group__header > * {
  background: rgba(16, 41, 84, 0.5);
  color: white;
  font-size: 1.2rem !important;
}
@media (max-width: 600px) {
  ::v-deep .v-data-table__mobile-row__cell {
    text-align: left !important;
  }

  ::v-deep tr.v-data-table__mobile-table-row td:first-child {
    margin-top: 2rem;
    background: rgba(223, 223, 223, 0.5);
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  ::v-deep tr.v-data-table__mobile-table-row td:last-child {
    padding-bottom: 2rem !important;
  }
}
</style>
