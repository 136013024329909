<template lang="pug">
    .proposal-maker-wrapper.fill-height
      v-row(dense v-if="activeLead").my-0.fill-height
        v-col(cols="12" lg="8", xl="7")
          .mb-6
              slot(name="left")
        v-col(cols="12" lg="4", xl="5").pa-0
          .d-flex.flex-column.justify-space-between.fill-height
            v-card.pa-12(tile flat color="#F9F7F9")
                h1.mb-6.display-2.text-h4.text-lg-h3 Create a Property Proposal for {{activeLeadName}}
                h2.mb-6.display-1.text-h5 You will be able to track which properties {{activeLead.firstName}} sees and how long he/she looks at them.
                slot(name="right")
            v-img(src="@/assets/apartment.jpg")
        
      v-alert(type="error" tile v-else) Oops, there was a system error

</template>

<script>
import { get } from "vuex-pathify";
export default {
  name: "ProposalMakerWrapper",
  computed: {
    ...get({
      activeLead: "Leads/activeLead",
      activeLeadName: "Leads/activeLeadName"
    })
  }
};
</script>

<style lang="scss" scoped></style>
