import { make } from "vuex-pathify";
import { isValidEmail, cleanObject } from "@/helpers.js";
import Firebase from "firebase/app";
import db from "@/plugins/db.js";

const EMPTY_LEAD = {
  basicInfo: {
    firstName: "",
    lastName: "",
    email: "",
    phonePrimary: "",
    stage: "COLD"
  },
  advancedInfo: {
    nickname: "",
    citizenship: "",
    currentLocation: "",
    secondaryEmail: "",
    secondaryPhone: ""
  }
};
const state = {
  activeLead: null,
  leads: [],
  dirtyLead: JSON.parse(JSON.stringify(EMPTY_LEAD)),
  stageOptions: [
    {
      text: "Cold Lead",
      value: "COLD",
      color: "blue lighten-3",
      icon: "mdi-snowflake-alert"
    },
    {
      text: "Warm Lead",
      value: "WARM",
      color: "red lighten-3",
      icon: "mdi-white-balance-sunny"
    },
    {
      text: "Hot Lead",
      value: "HOT",
      color: "purple lighten-2",
      icon: "mdi-flash"
    },
    { text: "", value: "" },
    {
      text: "-- Dormant",
      value: "DORMANT",
      color: "grey lighten-3",
      icon: "mdi-power-sleep"
    },
    {
      text: "-- Archived",
      value: "ARCHIVED",
      color: "grey lighten-1",
      icon: "mdi-sleep"
    }
  ]
};
const getters = {
  ...make.getters(state),
  getLeadById: state => id => {
    return state.leads.find(lead => lead.id === id);
  },
  activeLeadName: state => {
    console.log("when getting activeLeadName, ", state.activeLead.basicInfo);
    if (
      !state.activeLead ||
      !state.activeLead.basicInfo ||
      !state.activeLead.basicInfo.firstName
    )
      return "";
    const { firstName, lastName } = state.activeLead.basicInfo;
    if (firstName && lastName) {
      return firstName + " " + lastName;
    } else {
      return firstName;
    }
  }
};

const mutations = make.mutations(state);

const actions = {
  getProposalsForLead: async ({ getters, rootGetters }) => {
    try {
      if (!getters.activeLead || !getters.activeLead.id) return [];
      console.log("getting proposals for lead...", getters.activeLead);
      const leadId = getters.activeLead.id;
      const { agencyId } = rootGetters["User/userData"];

      const ref = await db
        .collection("agencies")
        .doc(agencyId)
        .collection("proposals")
        .where("leadId", "==", leadId)
        .get();

      return ref.docs.map(doc => doc.data());
    } catch (err) {
      console.error(err);
      return [];
    }
  },
  initNewLead: async ({ commit }) => {
    console.log("\n xxx initing new lead...");
    commit("SET_ACTIVE_LEAD", null);
    commit("SET_DIRTY_LEAD", JSON.parse(JSON.stringify(EMPTY_LEAD)));
  },
  upsertLead: async ({ getters, rootGetters, dispatch }, payload) => {
    try {
      const { isNewLead, basicInfo, advancedInfo } = payload;
      console.log("When upserting lead, basic info is", basicInfo);

      if (basicInfo) {
        if (basicInfo.firstName)
          basicInfo.firstName = basicInfo.firstName.trim();
        if (basicInfo.lastName) basicInfo.lastName = basicInfo.lastName.trim();
        if (basicInfo.email) basicInfo.email = basicInfo.email.trim();

        if (!basicInfo.stage)
          throw new Error(
            "You must select a stage (e.g. 'Cold' / 'Warm') for this lead"
          );

        if (isNewLead) {
          if (!basicInfo.email && !basicInfo.phonePrimary)
            throw new Error(
              "To create a new lead, you must provide either an email address or a primary phone number"
            );
          else if (basicInfo.email && !isValidEmail(basicInfo.email))
            throw new Error(
              "Oops, the email address you provided is not valid?"
            );
          else if (!basicInfo.firstName)
            throw new Error(
              "Uh oh, we'll need at least a first name for this lead"
            );
        }
      }

      const { agencyId } = rootGetters["User/userData"];
      console.log("agency will be", agencyId);

      if (!agencyId)
        throw new Error(
          "Oops, could not figure out what agency you're registered with. Please contact our support team."
        );

      const thisUser =
        Firebase.auth().currentUser && Firebase.auth().currentUser.uid
          ? Firebase.auth().currentUser.uid
          : null;

      if (!thisUser)
        throw new Error("Oops, are you sure you're logged in right now?");

      console.log("advancedInfo", advancedInfo);

      const cleanLead = {
        basicInfo: basicInfo
          ? cleanObject(basicInfo)
          : getters.dirtyLead.basicInfo,
        advancedInfo: advancedInfo
          ? cleanObject(advancedInfo)
          : getters.dirtyLead.advancedInfo,
        agentId: thisUser
      };

      const ref = db
        .collection("agencies")
        .doc(agencyId)
        .collection("leads")
        .doc(getters.activeLead ? getters.activeLead.id : undefined);

      await ref.set(cleanLead, { merge: true });

      await dispatch("bindLeads");
      return true;
    } catch (err) {
      console.error(err);
      throw new Error(err.message);
    }
  },
  bindActiveLead: ({ rootGetters, commit }, { leadId }) => {
    console.log("About to bind active lead for id", leadId);
    const { agencyId } = rootGetters["User/userData"];
    console.log("agency will be", agencyId);

    if (!agencyId)
      throw new Error(
        "Oops, could not figure out what agency you're registered with. Please contact our support team."
      );

    const thisUser =
      Firebase.auth().currentUser && Firebase.auth().currentUser.uid
        ? Firebase.auth().currentUser.uid
        : null;

    if (!thisUser)
      throw new Error("Oops, are you sure you're logged in right now?");

    const query = db
      .collection("agencies")
      .doc(agencyId)
      .collection("leads")
      .doc(leadId);

    return new Promise((resolve, reject) => {
      query.onSnapshot(
        async querySnapshot => {
          const obj = querySnapshot.data();
          console.log("binding active lead, got obj", obj);
          commit("SET_ACTIVE_LEAD", { ...obj, id: querySnapshot.id });
          commit("SET_DIRTY_LEAD", { ...getters.dirtyLead, ...obj });
          resolve({ ...obj, id: querySnapshot.id });
        },
        err => {
          console.log(`Encountered error: ${err}`);
          reject(err);
        }
      );
    });
  },
  bindLeads: ({ rootGetters, commit }) => {
    const { agencyId } = rootGetters["User/userData"];

    if (!agencyId)
      throw new Error(
        "Oops, could not figure out what agency you're registered with. Please contact our support team."
      );

    const thisUser =
      Firebase.auth().currentUser && Firebase.auth().currentUser.uid
        ? Firebase.auth().currentUser.uid
        : null;

    if (!thisUser)
      throw new Error("Oops, are you sure you're logged in right now?");

    const query = db
      .collection("agencies")
      .doc(agencyId)
      .collection("leads")
      .orderBy("lastActive", "desc"); // gets last 100 leads

    return new Promise((resolve, reject) => {
      query.onSnapshot(
        async querySnapshot => {
          let leads = [];
          for (const doc of querySnapshot.docs) {
            leads.push({
              id: doc.id,
              ...doc.data()
            });
          }

          console.log("Setting leads", leads);
          commit("SET_LEADS", leads);
          resolve();
        },
        err => {
          console.log(`Encountered error: ${err}`);
          reject(err);
        }
      );
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
