<template lang="pug">
    .date-filter
        v-btn(@click="setDateFilter" :outlined="activeFilter !== 'DATE'", :depressed="activeFilter === 'DATE'" color="primary" :block="$vuetify.breakpoint.smAndDown")
            v-icon.mr-2() mdi-clock-outline
            | {{activeFilter === "DATE" ? 'Now Sorting by Newest' : 'Sort by Newest'}}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DateFilter",
  computed: {
    ...mapGetters(["activeFilter"])
  },
  methods: {
    ...mapActions(["setDateFilter"])
  }
};
</script>
