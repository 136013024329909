<template lang="pug">
    v-container.no-leads-yet
        v-card(outlined max-width="900").pa-5.mx-auto
            v-row
                v-col(cols="12" md="4")
                    v-img(src="@/assets/crm/insights.svg" :max-width="$vuetify.breakpoint.mdAndDown ? 250 : 500").mx-auto
                v-col(cols="12" md="8")
                    h2.mb-3 Your leads haven't taken any actions yet
                    p
                        strong An insight is a little note about something important one of your leads has done.
                    p For example, when a lead opens your proposal, you'll see that mentioned here as an insight, because it tells you they're interested.
                    p To start seeing some insights, you'll need to add some leads and send them some proposals.

                    v-btn(x-large color="secondary" depressed to="/crm/CreateLead" block).mt-6
                        | Add a lead
                        v-icon.ml-2 mdi-plus-circle

                
    
</template>

<script>
export default {
  name: "NoLeadsYet"
};
</script>
