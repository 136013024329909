<template lang="pug">
  .layouts(:class="{debug: isDev}")
    .layout-default(v-if="!layout || layout === 'default'")
      v-app.pb-0
        NavDrawer
        v-main.bg
          router-view.adjust-for-footer
          Footer
          
    .layout-app(v-else-if="layout === 'app'")
      v-app
        v-main
          DialogExplainLeadStages
          router-view

</template>

<script>
import { get, call } from "vuex-pathify";

const NavDrawer = () => import("@/components/NavDrawer.vue");
import DialogExplainLeadStages from "@/components/CRM/Dialogs/DialogExplainLeadStages.vue";
const Footer = () => import("@/components/Footer.vue");

export default {
  name: "App",

  components: {
    NavDrawer,
    DialogExplainLeadStages,
    Footer
  },

  data: () => ({
    //
  }),
  created() {
    this.setSources();
    this.$vuetify.theme.themes.light = { ...this.customThemes[this.layout] };
  },
  computed: {
    ...get(["layout", "customThemes"]),
    isDev() {
      return window && !!window.webpackHotUpdate;
    }
  },
  methods: {
    ...call({ setSources: "setSources" })
  }
};
</script>

<style lang="scss">
@import "@/assets/css/colors.scss";

pre.debug {
  display: none;
}
.debug pre.debug {
  display: inherit;
}

.bg {
  background: $muted;
}

.layout-default {
  @import "@/assets/css/layout-default.scss";
  scroll-behavior: smooth;
}

.layout-app {
  @import "@/assets/css/layout-app.scss";
}
</style>
