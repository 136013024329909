import Vue from "vue";
import Vuex from "vuex";
import db from "@/plugins/db.js";
import pathify from "vuex-pathify";
import { make } from "vuex-pathify";
Vue.use(Vuex);

import Cleaner from "@/store/modules/Cleaner";
import Enums from "@/store/modules/Enums";

// Proposals
import ProposalMaker from "@/store/modules/ProposalMaker";
import ProposalViewer from "@/store/modules/ProposalViewer";
import Analytics from "@/store/modules/Analytics";

// CRM
import User from "@/store/modules/User";
import CrmNav from "@/store/modules/Nav";
import Leads from "@/store/modules/Leads";
import Insights from "@/store/modules/Insights";

const DEFAULT_PRICE_FILTER = {
  text: "Filter By Price",
  operator: ">",
  amount: 0
};

const state = {
  activeFilter: "DATE",
  priceFilter: DEFAULT_PRICE_FILTER,
  listings: [],
  listingsPerPage: 10,
  sources: [],
  noMoreListings: false,
  latestListing: null,
  layout: "default",
  customThemes: {
    default: {
      muted: "#eeeeee",
      primary: "#222831",
      secondary: "#4f8a8b",
      accent: "#fbd46d"
      // error: "#b71c1c",
      // warning: "#ff5722",
      // info: "#607d8b",
      // success: "#8bc34"
    },
    app: {
      muted: "#eeeeee",
      primary: "#394958",
      secondary: "#00A3BB",
      accent: "#A15A51",
      error: "#b71c1c",
      warning: "#ff5722",
      info: "#607d8b",
      success: "#8bc34a"
    }
  }
};

const actions = {
  setSources: async ({ commit }) => {
    const sourcesRef = db.collection("updateList");
    const res = await sourcesRef.get();

    const sources = res.docs.map(doc => doc.data());
    // console.log("Setting sources to", sources);
    commit("SET_SOURCES", sources);
  },
  setDateFilter: ({ commit, dispatch }) => {
    commit("SET_ACTIVE_FILTER", "DATE");
    commit("SET_LATEST_LISTING", null);
    commit("SET_PRICE_FILTER", DEFAULT_PRICE_FILTER);
    commit("SET_LISTINGS", []);
    dispatch("getListings");
    window.scrollTo(0, 0);
  },
  setPriceFilter: ({ commit, dispatch }, payload) => {
    commit("SET_ACTIVE_FILTER", "PRICE");
    commit("SET_LATEST_LISTING", null);
    commit("SET_PRICE_FILTER", payload);
    commit("SET_LISTINGS", []);
    dispatch("getListings");
    window.scrollTo(0, 0);
  },
  getListings: async ({ commit, getters }) => {
    commit("SET_NO_MORE_LISTINGS", false);
    let listingsRef;

    console.log("activeFilter = ", getters.activeFilter);

    if (getters.activeFilter === "DATE") {
      listingsRef = db.collection("listings").orderBy("created", "desc");
    } else if (getters.activeFilter === "PRICE") {
      // console.log(
      //   "Getting price resuls, with priceFilter",
      //   getters.priceFilter
      // );
      listingsRef = db
        .collection("listings")
        .where("regularPrice.price", ">=", getters.priceFilter.amount1)
        .where("regularPrice.price", "<=", getters.priceFilter.amount2)
        .orderBy("regularPrice.price", "asc")
        .orderBy("created", "desc");
    }

    let startAfter;
    if (getters.latestListing) {
      // console.log("\n\n starting afer...");
      // startAfter = getters.latestListing.get("created");
      startAfter = getters.latestListing;

      // console.log("Setting startafter to", startAfter);
    }

    let res;

    if (startAfter) {
      // console.log("GETTING LISTINGS WITH A START-AFTER VALUE...");
      res = await listingsRef
        .startAfter(startAfter)
        .limit(getters.listingsPerPage)
        .get();
    } else {
      // console.log("GETTING LISTINGS --- WITHOUT --- A START-AFTER VALUE");
      res = await listingsRef.limit(getters.listingsPerPage).get();
    }

    const listings = res.docs.map(doc => doc.data());
    // console.log("\n\n\n***** Got listings", listings);

    if (!listings || listings.length < 1) {
      commit("SET_NO_MORE_LISTINGS", true);
      return;
    }

    if (listings.length < getters.listingsPerPage) {
      commit("SET_NO_MORE_LISTINGS", true);
    }

    const listingAlreadyExists =
      getters.listings.length > 0 &&
      getters.listings.find(
        listing => listing.permalink === listings[0].permalink
      );

    // console.log("listingAlreadyExists = ", listingAlreadyExists);

    if (listingAlreadyExists) {
      commit("SET_NO_MORE_LISTINGS", true);
      return;
    }

    // console.log("Now Appending listings", listings);
    commit("appendListings", listings);
    commit("SET_LATEST_LISTING", res.docs[res.size - 1]);
  }
};

export default new Vuex.Store({
  namespaced: true,
  plugins: [pathify.plugin], // activate plugin
  state,
  getters: {
    isDev: () => window && !!window.webpackHotUpdate,
    hostname: () =>
      window && !!window.webpackHotUpdate
        ? "http://localhost:8888"
        : "https://bgProperty.today",
    ...make.getters(state)
  },
  mutations: {
    appendListings: (state, payload) => state.listings.push(...payload),
    ...make.mutations(state)
  },
  actions,
  modules: {
    Enums,
    ProposalMaker,
    ProposalViewer,
    Cleaner,
    Analytics,
    User,
    CrmNav,
    Leads,
    Insights
  }
});
