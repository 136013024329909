var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"counts"},[_c('h3',[_vm._v("View Counts")]),_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","sortBy":"views","sortDesc":"","hide-default-header":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"item.views",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"label"},[_vm._v("Views")]):_vm._e(),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.views))])]}},{key:"item.listing",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"label"},[_vm._v("Listing")]):_vm._e(),_c('a',{staticClass:"text-decoration-none",attrs:{"href":item.url && item.url.length > 2 ? item.url : null,"target":"_blank","rel":"noopener"}},[_c('b',[_vm._v(_vm._s(item.title))])])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"label"},[_vm._v("Url")]):_vm._e(),_c('a',{attrs:{"href":item.url && item.url.length > 2 ? item.url : null,"target":"_blank","rel":"noopener"}},[_vm._v(_vm._s(item.url))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"label"},[_vm._v("Price")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.price))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }