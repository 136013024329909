import "firebase/auth";
import db from "@/plugins/db.js";
import { make } from "vuex-pathify";
import { onEnterLeave, onOtherEvents } from "./_analyticsEvents";

import store from "@/store/index";

const state = {
  counts: null,
  events: null
};

const parseRawEvents = async (rawEvents, properties) => {
  if (!rawEvents) return [];

  return Object.entries(rawEvents).map(event => {
    const { source, type, value } = event[1];

    const property = properties.find(property => property.url === value);
    return {
      created: event[1].created.toDate(),
      source,
      url: source,
      type,
      value,
      property: property
        ? {
            title: property.title,
            sourceName: property.sourceName,
            price: property.price,
            url: property.url,
            image:
              property.images && property.images.length > 0
                ? property.images[0]
                : null
          }
        : null
    };
  });
};

const parseRawCounts = async (rawCounts, properties) => {
  if (!rawCounts) return [];

  const clean = Object.entries(rawCounts).map(itm => {
    const url = itm[0];
    const property = properties.find(property => property.url === url);
    return {
      url,
      views: itm[1].views,
      property
    };
  });

  return clean;
};

const getters = {
  ...make.getters(state)
  // proposalUrl: (state, getters, rootState, rootGetters) => {
  //   const activeProposal = rootGetters["ProposalViewer/activeProposal"];
  //   if (!activeProposal) return "";
  //   return (
  //     location.protocol +
  //     "//" +
  //     location.host +
  //     "/proposals/" +
  //     activeProposal.agencyId +
  //     "/" +
  //     activeProposal.id
  //   );
  // },
  // proposalViewCount: (state, getters) => {
  //   if (!getters.proposalUrl) return "";

  //   if (
  //     !state ||
  //     !state.counts ||
  //     !state.counts.find(itm => itm.url === getters.proposalUrl)
  //   )
  //     return 0;

  //   return state.counts.find(itm => itm.url === getters.proposalUrl).views;
  // }
};

const mutations = make.mutations(state);

const actions = {
  fireEvent: async ({ rootGetters }, { source, type, value }) => {
    try {
      const { isAgent } = rootGetters["User/userData"];

      if (!isAgent) {
        const activeProposal = rootGetters["ProposalViewer/activeProposal"];
        const { id: proposalId, agencyId } = activeProposal;

        if (proposalId && agencyId) {
          if (type === "ENTER_PROPOSAL") {
            onEnterLeave({ agencyId, proposalId, source, type, value });
          } else if (type === "LEAVE_PROPOSAL") {
            onEnterLeave({ agencyId, proposalId, source, type, value });
          } else if (type === "ENTER_PROPERTY" || type === "LEAVE_PROPERTY")
            onEnterLeave({ agencyId, proposalId, source, type, value });
          else onOtherEvents({ agencyId, proposalId, source, type, value });
        }
      } else {
        // console.log("Not firing event because it is a dashboard view");
      }
    } catch (err) {
      console.error(err);
    }
  },
  bindAnalytics: async ({ commit, rootGetters }, docType) => {
    console.log("binding analytics...");
    return new Promise((resolve, reject) => {
      try {
        const activeProposal = rootGetters["ProposalViewer/activeProposal"];
        console.log("when binding analytics, activeProposal", activeProposal);
        const { agencyId, id: proposalId } = activeProposal;

        const query = db
          .collection("agencies")
          .doc(agencyId)
          .collection("proposals")
          .doc(proposalId)
          .collection("analytics")
          .doc(docType);

        query.onSnapshot(async querySnapshot => {
          const data = querySnapshot.data();
          if (!data) {
            reject("no analytics found");
          } else {
            const links =
              docType === "counts"
                ? Object.keys(data)
                : Object.values(data).map(val => val.value);
            const properties = await store.dispatch(
              "ProposalMaker/getPropertiesForLinks",
              {
                links
              },
              { root: true }
            );

            if (docType === "counts") {
              parseRawCounts(data, properties).then(parsedCounts =>
                commit("SET_" + docType.toUpperCase(), parsedCounts)
              );
            } else if (docType === "events") {
              parseRawEvents(data, properties).then(parsedEvents =>
                commit("SET_" + docType.toUpperCase(), parsedEvents)
              );
            }
            resolve();
          }
        });
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
