<template lang="pug">
    .profile.py-3.py-md-12
        v-container(fluid).text-center
          .title.mb-3
            .text-h4 Profile
          span Edit the contact info that appears on your proposals
        
        v-container
            v-row(align="stretch")
                v-col(cols="12" md="6")
                    v-card.pa-5(color="grey lighten-4" flat height="100%").mb-5
                        h3.mb-3 Main Info
                        .d-flex.flex-column.flex-md-row
                            v-text-field#firstName(v-model="firstName", label="First name", placeholder="*First Name", outlined, :rules="[]").mr-md-2
                            v-text-field#lastName(v-model="lastName", label="Last name", placeholder="*Last Name", outlined, :rules="[]")
                        v-text-field#email(v-model="email", label="Your email", type="email", placeholder="*Email address", outlined, :rules="[rules.email]" hide-details)
                        .mt-3
                            small * The email address you set above will be seen by your clients, but you will still use {{currentUserEmail}} to login
                v-col(cols="12" md="6")
                    v-card.pa-5(color="" flat height="100%")
                        h3.mb-3 Contact Info
                        v-text-field#primaryPhone(v-model="primaryPhone", label="Primary Phone (optional)" placeholder="+359 1234-5678", outlined, :rules="[]" clearable)
                        v-text-field#secondaryPhone(v-model="secondaryPhone", label="Secondary Phone (optional)" placeholder="+359 1234-5678", outlined, :rules="[]" clearable)
                        v-textarea#contactNotes(v-model="contactNotes", rows="3" label="Contact Instructions" placeholder="The best time to contact me is Monday - Friday, in the mornings. My first phone number (+359 1234-5678) is used for WhatApp/Viber.", outlined, :rules="[]" hide-details)
                
            
            v-row
                v-col(cols="12" md="6" offset-md="3")        
                    v-alert.error-saving(v-if="errorSaving", type="error" outlined border="left").mt-3 {{errorSaving}}
                    .text-center
                        v-btn(:color="btnSave.color", x-large, depressed, @click="handleClickSave").mt-3
                            v-icon.mr-2 {{btnSave.icon}}
                            | {{btnSave.text}}
        
            v-row(v-if="agencyData && agencyData.branding" justify="center").grey.lighten-4.mt-12
              v-col(cols="12" lg="4")
                h3.my-3 Branding Info
                p In addition to your agent info, your proposals will be branded under your agency.
                p Here you can see a preview of what that branding will look like.
                template(v-if="userData.isOwner")
                  p
                    b Since you are an owner of this agency account, you can edit how this branding information will appear across your entire agency.
                  p To do that, press the "Edit" button in the top-right corner of the Branding Preview box.
              v-col(cols="12" lg="4").text-center
                AgencyBrandingPreview
</template>

<script>
import { get, sync, call } from "vuex-pathify";
import { isValidEmail } from "@/helpers";
import Firebase from "firebase/app";
import AgencyBrandingPreview from "@/components/CRM/Account/AgencyBrandingPreview.vue";

const DEFAULT_SAVE_BUTTON = {
  text: "Save Agent Info",
  icon: "mdi-account-edit",
  color: "primary"
};
export default {
  name: "Profile",
  components: { AgencyBrandingPreview },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      primaryPhone: "",
      secondaryPhone: "",
      contactNotes: "",
      btnSave: DEFAULT_SAVE_BUTTON,
      errorSaving: null,
      rules: {
        required: value => !!value || "Required.",
        email: value => {
          return isValidEmail(value) || "Please enter your best e-mail.";
        }
      }
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...get({
      userData: "User/userData",
      agencyData: "User/agencyData"
    }),
    ...sync({
      showEditAgencyBranding: "CrmNav/dialogs@showEditAgencyBranding"
    }),
    currentUserEmail() {
      return Firebase.auth().currentUser.email;
    }
  },
  methods: {
    ...call({ upsertUserData: "User/upsertUserData" }),
    init() {
      Object.entries(this.userData).forEach(
        entry => (this[entry[0]] = this.userData[entry[0]])
      );
    },
    async handleClickSave() {
      console.log("About to save updates...");
      this.errorSaving = null;

      const {
        firstName,
        lastName,
        email,
        primaryPhone,
        secondaryPhone,
        contactNotes
      } = this;
      const res = await this.upsertUserData({
        firstName,
        lastName,
        email,
        primaryPhone,
        secondaryPhone,
        contactNotes
      });

      if (res === true) {
        this.btnSave = {
          text: "Saved!",
          icon: "mdi-check",
          color: "success"
        };

        setTimeout(() => {
          this.btnSave = DEFAULT_SAVE_BUTTON;
        }, 2000);
      } else {
        this.errorSaving = res;
      }
    }
  }
};
</script>
