<template lang="pug">
    .create-lead.pa-12
        v-container(fluid).text-center.mb-6
          .title.mb-3
            .text-h4 {{leads && leads.length > 0 ? leads.length : ''}} Leads
          span Manage and Track all your real estate customers
          
        v-container
            NoLeadsYet(v-if="!leads || leads.length < 1")
            
            template(v-else)
              v-row(align="stretch")
                  v-col(cols="12" md="6" lg="4" xl="3")
                    AddNewLeadCard
                  v-col(cols="12" md="6" lg="4" xl="3", v-for="lead in leads", :key="lead.id", )
                    LeadCardShort(:lead="lead")
</template>

<script>
import NoLeadsYet from "@/components/CRM/Leads/Partials/NoLeadsYet.vue";
import AddNewLeadCard from "@/components/CRM/Leads/AddNewLeadCard.vue";
import LeadCardShort from "@/components/CRM/Leads/LeadCardShort.vue";
import { call, sync } from "vuex-pathify";
export default {
  name: "Leads",
  components: { NoLeadsYet, AddNewLeadCard, LeadCardShort },
  data() {
    return {
      errorMessage: null
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...sync({
      leads: "Leads/leads"
    })
  },
  methods: {
    ...call({ bindLeads: "Leads/bindLeads" }),
    init() {
      this.activeLeadId = undefined;
      this.bindLeads();
    }
  }
};
</script>

<style lang="scss" scoped></style>
