<template lang="pug">
    .copy-sharing-link.d-flex.align-center
        v-text-field(:background-color="btnCopy.color === 'success' ? 'success' : null" hide-details readonly outlined :value="proposalLink")
        v-btn(depressed :color="btnCopy.color" x-large @click="handleCopy(proposalLink)").ml-2 {{btnCopy.text}}
</template>

<script>
import { copyToClipboard } from "@/helpers";
export default {
  name: "CopySharingLink",
  props: {
    proposalLink: { type: String, required: true }
  },
  data() {
    return {
      btnCopy: { text: "Copy", color: "primary" }
    };
  },
  methods: {
    handleCopy(str) {
      copyToClipboard(str);
      this.btnCopy.text = "Copied!";
      this.btnCopy.color = "success";

      setTimeout(() => {
        this.btnCopy.text = "Copy";
        this.btnCopy.color = "primary";
      }, 2000);
    }
  }
};
</script>
