<template lang="pug">
    .filters(:class="inline ? 'px-6 px-md-12' : null")
        v-card(:color="inline ? null : 'white'" :raised="!inline" :outlined="inline").pa-3
            v-row(align="center" justify="center")
                v-col(cols="12", md="auto" v-if="$vuetify.breakpoint.mdAndUp")
                    h3.title Sort & Filter...
                v-col(cols="12", md="auto")
                    PriceFilter
                v-col(cols="12", md="auto")
                    DateFilter
</template>

<script>
import DateFilter from "@/components/DateFilter";
import PriceFilter from "@/components/PriceFilter";
import { mapGetters } from "vuex";
export default {
  name: "Filters",
  components: { PriceFilter, DateFilter },
  props: { inline: { type: Boolean, default: false } },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["priceFilter"])
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
</style>
