<template lang="pug">
  .maker-options.mt-6
    h3 Options
    v-checkbox(v-for="option in optionsList", v-model="selectedOptions" :key="option.value", :label="option.text", :value="option.value", hide-details, :disabled="option.disabled")
</template>

<script>
import { sync } from "vuex-pathify";
import BtnNext from "@/components/Proposals/Maker/BtnNext";
export default {
  name: "TabCard",
  components: { BtnNext },
  props: {},
  computed: {
    ...sync({
      optionsList: "ProposalMaker/optionsList",
      selectedOptions: "ProposalMaker/selectedOptions"
    })
  }
};
</script>
