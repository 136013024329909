import { make } from "vuex-pathify";
// import store from "@/store/index";
const state = {
  showNav: true,
  miniNav: false,
  activeDashboardComponent: null,
  dialogs: {
    showEditAgencyBranding: false,
    showDialogExplainLeadStages: false
  }
};
const getters = {
  ...make.getters(state)
};

const mutations = make.mutations(state);

import Profile from "@/components/CRM/Account/Profile.vue";
import Leads from "@/components/CRM/Leads/Leads.vue";
import Insights from "@/components/CRM/Insights/Insights.vue";
import CreateLead from "@/components/CRM/Leads/CreateLead.vue";
import LeadDetails from "@/components/CRM/Leads/LeadDetails.vue";
import CreateProposal from "@/components/CRM/Proposals/CreateProposal.vue";

const components = {
  Leads,
  Insights,
  CreateLead,
  Profile,
  CreateProposal,
  LeadDetails
};
const actions = {
  setActiveDashboardComponent: ({ commit }, { page }) => {
    commit("SET_ACTIVE_DASHBOARD_COMPONENT", components[page]);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
