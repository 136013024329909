<template lang="pug">
    .edit-basic-info-lead
      .d-flex.flex-column.flex-md-row
        v-text-field(v-model="advancedInfo.nickname",outlined  placeholder="Jimmy, Tommy, Sasha" label="Nickname")
      
      .d-flex.flex-column.flex-md-row
        v-autocomplete(x-large v-model="advancedInfo.citizenship", :items="countries", item-text="value", item-value="key", outlined  placeholder="United Kingdom" label="Citizenship").mr-md-4
        v-autocomplete(x-large v-model="advancedInfo.currentLocation", :items="countries", item-text="value", item-value="key", outlined  placeholder="Bulgaria" label="Current Location")
      .d-flex.flex-column.flex-md-row
        v-text-field(v-model="advancedInfo.secondaryEmail",outlined  placeholder="james.smith@example.com" label="Secondary Email").mr-md-4
        v-text-field(v-model="advancedInfo.secondaryPhone",outlined  placeholder="+44 1234-5678" label="Secondary Phone")
      
      v-btn(:depressed="depressed", :outlined="outlined", :color="button.color" block x-large :loading="loading", @click="handleClick") {{button.text}}
      v-alert(type="error" outlined border="left" v-if="errorMessage").my-3 {{errorMessage}}
      .text-center(v-if="showCancel")
        v-btn(text color="accent" to="/crm/Leads").mt-2 Cancel
</template>

<script>
import { call, sync, get } from "vuex-pathify";
export default {
  name: "LeadEditAdvancedInfo",
  props: {
    buttonText: { type: String, default: "Save info" },
    showCancel: { type: Boolean, default: true },
    onSaveGoBackToLeadsList: { type: Boolean, default: false },
    depressed: { type: Boolean, default: true },
    outlined: { type: Boolean, default: false }
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      button: {
        text: "",
        color: "primary"
      }
    };
  },
  created() {
    this.button.text = this.buttonText;
  },
  computed: {
    ...get({
      countries: "Enums/countries"
    }),
    ...sync({
      advancedInfo: "Leads/dirtyLead@advancedInfo"
    })
  },
  methods: {
    ...call({ upsertLead: "Leads/upsertLead" }),
    async handleClick() {
      console.log("handling click...");
      try {
        this.loading = true;
        this.errorMessage = null;
        const res = await this.upsertLead({ advancedInfo: this.advancedInfo });
        console.log("got res", res);
        if (res) {
          this.button.text = "Saved";
          this.button.color = "success";
          setTimeout(() => {
            this.button.text = this.buttonText;
            this.button.color = "primary";
          }, 2000);
          if (this.onSaveGoBackToLeadsList)
            this.$router.push({ path: "/crm/Leads" });
        } else {
          this.errorMessage = res;
        }
      } catch (err) {
        this.errorMessage = err;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
