import Firebase from "firebase/app";
import "firebase/auth";
import db from "@/plugins/db.js";
import { make } from "vuex-pathify";

const state = {
  previewImage: null,
  showPreviewImage: false,
  supportedSites: [
    { name: "Bansko Realty", url: "https://banskorealty.com/" },
    { name: "Bower Properties", url: "https://bower-properties.com/" },
    {
      name: "Bulgarian Properties",
      url: "https://www.bulgarianproperties.com"
    },
    { name: "Excel Property", url: "https://excelpropertybulgaria.com/" },
    { name: "SuprImmo", url: "https://www.suprimmo.net/" },
    { name: "Unikalnii Imoti", url: "https://unikalniimotibansko.com" }
  ]
};

const getters = {
  ...make.getters(state)
};

const mutations = make.mutations(state);

const actions = {
  translate: async (context, { text }) => {
    const translate = Firebase.functions().httpsCallable("translate");
    const res = await translate({ text });
    return res.data;
  },
  addReadableListing: async (context, { url }) => {
    const addReadableListing = Firebase.functions().httpsCallable(
      "addReadableListing"
    );
    return await addReadableListing({ url });
  },
  getReadableListing: async (context, { url }) => {
    console.log("getting readable listing at url", url);
    const ref = await db
      .collection("readableListings")
      .where("url", "==", url)
      .limit(1)
      .get();
    return ref.docs && ref.docs.length > 0 ? ref.docs[0].data() : null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
