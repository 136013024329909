export const isValidEmail = str => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(str).toLowerCase());
};

export const isValidPassword = str => {
  const re = /^(?=.*[a-z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
  return re.test(String(str));
};

export const isValidUrl = str => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gi;
  return re.test(String(str).toLowerCase());
};

/**
 * @param  {} obj
 * Strips null properties from object
 */
export const cleanObject = (obj, considerEmptyStringsToBeNull = true) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      (considerEmptyStringsToBeNull && obj[propName] === "")
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const capitalize = (str, capitalizeAfterSpecialChars = false) => {
  if (!str || str.length < 1) return null;

  const v1 = str[0].toUpperCase() + str.substr(1, str.length - 1);
  if (!capitalizeAfterSpecialChars) return v1;

  const arr = v1.split("-");
  const combo = arr.map(itm => capitalize(itm));
  return combo.join("-");
};

export const waitForElemToExist = selector => {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
};

export const copyToClipboard = str => {
  /* ——— Derived from: https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
         improved to add iOS device compatibility——— */
  const el = document.createElement("textarea"); // Create a <textarea> element

  const storeContentEditable = el.contentEditable;
  const storeReadOnly = el.readOnly;

  el.value = str; // Set its value to the string that you want copied
  el.contentEditable = true;
  el.readOnly = false;
  el.setAttribute("readonly", false); // Make it readonly false for iOS compatability
  el.setAttribute("contenteditable", true); // Make it editable for iOS
  el.style.position = "absolute";
  el.style.left = "-9999px"; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  el.setSelectionRange(0, 999999);
  document.execCommand("copy"); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }

  el.contentEditable = storeContentEditable;
  el.readOnly = storeReadOnly;
};
