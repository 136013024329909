<template lang="pug">
    .maker-section--sharing
      TabCard(:title="'Share your proposal link with ' + activeLeadName", :subtitle="'It expires in ' + expiresInDays + ' days. Share this link with ' + activeLeadName + ' via email or text.'")
        v-row(dense align="center").mt-5
          v-col(cols="7" sm="10" md='8')
            v-text-field(outlined hide-details readonly :value="proposalLink").grey.lighten-3
          v-col(cols="5" sm="2" md="4")
            v-btn.copy(x-large :color="btnCopy.color" outlined tile  :block="$vuetify.breakpoint.mdAndDown", @click="handleCopy") {{btnCopy.text}}
        
        v-row(dense)
          v-col(cols="12" md="6")
            v-btn(:x-large='$vuetify.breakpoint.lgAndUp' block, color="success" depressed :href="proposalLink" target="_blank").mt-6
              v-icon.mr-2 mdi-eye
              | View Proposal

          v-col(cols="12" md="6")
            v-btn(:x-large='$vuetify.breakpoint.lgAndUp' :block="$vuetify.breakpoint.mdAndDown", color="accent" text @click="init").mt-3.mt-md-6
              v-icon.mr-2 mdi-refresh
              | New proposal
</template>

<script>
import { copyToClipboard } from "@/helpers";
import { get, call } from "vuex-pathify";
import TabCard from "@/components/CRM/Proposals/Maker/TabCard";
import AgencyBrandingPreview from "@/components/CRM/Account/AgencyBrandingPreview.vue";
export default {
  name: "MakerSectionSharing",
  components: { TabCard, AgencyBrandingPreview },
  data() {
    return {
      btnCopy: { text: "Copy", color: "primary" }
    };
  },

  computed: {
    ...get({
      activeLead: "Leads/activeLead",
      activeLeadName: "Leads/activeLeadName",
      proposalLink: "ProposalMaker/proposalLink",
      expiresInDays: "ProposalMaker/expiresInDays"
    })
  },
  methods: {
    ...call({ init: "ProposalMaker/init" }),
    handleCopy() {
      copyToClipboard(this.proposalLink);
      this.btnCopy.text = "Copied!";
      this.btnCopy.color = "success";

      setTimeout(() => {
        this.btnCopy.text = "Copy";
        this.btnCopy.color = "primary";
      }, 2000);
    },
    getLinkTitle(url) {
      const foundListing = this.readableListings.find(
        listing => listing.url.toLowerCase() === url
      );
      return foundListing ? foundListing.title : null;
    }
  }
};
</script>
