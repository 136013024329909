<template lang="pug">
  .page-home
    v-container(fluid).pa-0
      Filters
    v-container
      Listings
    v-container(fluid).pa-0
      Filters.mt-6(inline)
</template>

<script>
// @ is an alias to /src
import Filters from "@/components/Filters.vue";
import Listings from "@/components/Listings.vue";

export default {
  name: "Home",
  components: {
    Filters,
    Listings
  }
};
</script>
