<template lang="pug">
    v-dialog.dialog-explain-lead-stages(v-model="showDialogExplainLeadStages")
        v-card.pa-3.pa-md-12
            .title.text-center.font-weight-bold.mb-3 What are 'Cold', 'Warm', or 'Hot' Leads?
            p Every 'CRM System' for managing new and existing customers has the concept of <em>leads</em>.
            p Leads are people who <b>haven't made a deal and paid you yet</b>, and they are somewhere on the path to doing that.
            v-divider.my-3
            p At its simplest...
            ul
                li A <b>'Cold Lead'</b> is someone you're bringing in for the first time.
                li A <b>'Warm Lead'</b> or a 'Qualified Lead' is someone who has shown you they have serious potential to buy from you.
                li A <b>'Hot Lead'</b> is someone who you think is right on the edge of buying.

            v-divider.my-3
            p A couple other types...

            ul
                li A <b>'Dormant Lead'</b> is someone who lost contact with you, but they might come back later.
                li An <b>'Archived Lead'</b> is someone who you doubt is coming back later.
            v-card-actions.justify-end
                    v-btn(color="primary", depressed, @click="showDialogExplainLeadStages = false")
                        v-icon.mr-1 mdi-check
                        | Got it
</template>

<script>
import { sync } from "vuex-pathify";
export default {
  name: "DialogExplainLeadStages",
  computed: {
    ...sync({
      showDialogExplainLeadStages: "CrmNav/dialogs@showDialogExplainLeadStages"
    })
  }
};
</script>
