import { make } from "vuex-pathify";
import Firebase from "firebase/app";
import db from "@/plugins/db.js";
import present from "present";
// import { sortBy } from "lodash";
// const { nanoid } = require("nanoid");
const state = {
  isRefreshing: false,
  insights: [],
  events: []
};
import sortBy from "lodash.sortby";
const getters = {
  ...make.getters(state),
  prettyIcon: () => (type, value) => {
    if (type === "ENTER_PROPOSAL") {
      return { icon: "mdi-source-commit-start" };
    } else if (type === "LEAVE_PROPOSAL") {
      return { icon: "mdi-exit-to-app" };
    } else if (type === "COUNT_VIEWED_PROPOSAL") {
      return { icon: "mdi-eye", badge: value };
    } else if (type === "COUNT_VIEWED_PROPERTY") {
      return { icon: "mdi-text-box-search-outline", badge: value };
    } else return "mdi-lightning-bolt-outline";
  },
  prettyType: () => ({ source, type, value }) => {
    if (type === "ENTER_PROPOSAL") {
      return "Opened Proposal";
    } else if (type === "LEAVE_PROPOSAL") {
      return "Closed Proposal";
    } else if (type === "COUNT_VIEWED_PROPOSAL") {
      return `Viewed <a href='${source}' target='_blank' rel='noopener'>the proposal</a> ${value ||
        "several"} times`;
    } else if (type === "COUNT_VIEWED_PROPERTY") {
      return `Viewed <a href='${source}' target='_blank' rel='noopener'>a property</a> ${value ||
        "several"} times`;
    } else return type;
  },
  prettyColor: () => (type, value) => {
    if (type === "ENTER_PROPOSAL") {
      return "success";
    } else if (
      type === "COUNT_VIEWED_PROPOSAL" ||
      type === "COUNT_VIEWED_PROPERTY"
    ) {
      if (value >= 20) return "red";
      else if (value >= 15) return "purple";
      else if (value >= 10) return "pink";
      else if (value >= 5) return "success";
      else if (value >= 2) return "primary";
      else return null;
    } else if (type === "LEAVE_PROPOSAL") {
      return "warning";
    } else return null;
  }
};

const mutations = make.mutations(state);

const actions = {
  addInsight: async (context, { agencyId, leadId, type, source, value }) => {
    console.log("About to add insight", agencyId, leadId, type, source, value);

    const insightId = present(); // timestamp to nanosecond
    db.collection("agencies")
      .doc(agencyId)
      .collection("insights")
      .doc(leadId)
      .set(
        {
          [insightId]: { type, source, value, created: new Date() }
        },
        { merge: true }
      );
  },
  bindInsights: async ({ rootGetters, commit, dispatch }) => {
    try {
      commit("SET_IS_REFRESHING", true);

      await dispatch("Leads/bindLeads", {}, { root: true });
      // await dispatch("refreshInsightsForAllLeads");

      const { agencyId } = rootGetters["User/userData"];

      if (!agencyId)
        throw new Error(
          "Oops, could not figure out what agency you're registered with. Please contact our support team."
        );

      const thisUser =
        Firebase.auth().currentUser && Firebase.auth().currentUser.uid
          ? Firebase.auth().currentUser.uid
          : null;

      if (!thisUser)
        throw new Error("Oops, are you sure you're logged in right now?");

      const mostRecentlyActiveLeadsRef = await db
        .collection("agencies")
        .doc(agencyId)
        .collection("leads")
        .orderBy("lastActive", "asc")
        .limit(10)
        .get();
      const mostRecentlyActiveLeads = mostRecentlyActiveLeadsRef.docs.map(
        doc => doc.id
      );

      const query = db
        .collection("agencies")
        .doc(agencyId)
        .collection("insights")
        .where(
          Firebase.firestore.FieldPath.documentId(),
          "in",
          mostRecentlyActiveLeads
        );

      console.log("binding insights for agency id", agencyId);

      return new Promise((resolve, reject) => {
        query.onSnapshot(
          async querySnapshot => {
            console.log("UPDATING INSIGHTS....");
            let insights = [];
            for (const doc of querySnapshot.docs) {
              const lead = rootGetters["Leads/leads"].find(
                lead => lead.id === doc.id
              );
              const data = doc.data();

              const eventObjects = Object.values(data).filter(
                itm => !Array.isArray(itm)
              );

              const events = eventObjects.map(itm => {
                return {
                  ...itm,
                  leadId: doc.id,
                  lead,
                  created: itm.created.toDate()
                };
              });

              insights.push(...events);
            }

            const sortedAndReversed = sortBy(insights, "created").reverse();
            console.log("Setting insights to", sortedAndReversed);
            commit("SET_INSIGHTS", sortedAndReversed);
            resolve();
          },
          err => {
            console.log(`Encountered error: ${err}`);
            reject(err);
          }
        );
      });
    } catch (err) {
      console.error(err);
    } finally {
      commit("SET_IS_REFRESHING", false);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
