<template lang="pug">
    v-btn.btnNext(color="primary" depressed x-large @click="$emit('click')") {{text}}
        v-icon.ml-1(v-if="icon !== null") {{icon}}
</template>

<script>
export default {
  name: "BtnNext",
  props: {
    text: { type: String, default: "Next" },
    icon: { type: String, default: "mdi-arrow-right" }
  }
};
</script>
