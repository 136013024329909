import { make } from "vuex-pathify";
const countryList = require("country-list");
import sortBy from "lodash.sortby";
const state = {};
const getters = {
  ...make.getters(state),
  countries: () => {
    const raw = Object.entries(countryList.getCodeList()).map(itm => {
      return { key: itm[0], value: itm[1] };
    });

    const topTargets = ["bg", "us", "ca", "uk", "ru", "ua", "au", "nz"];
    const top = raw.filter(itm => topTargets.includes(itm.key));
    const sorted = sortBy(raw, "value");

    return [...top, ...sorted];
  }
};

const mutations = make.mutations(state);

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
