<template lang="pug">
    .proposal-maker.grey.lighten-3.fill-height
      ProposalMakerWrapper
        template(#left)
            v-card#top.pa-md-12(flat :width="$vuetify.breakpoint.mdAndUp ? '80%' : '95%'", color="rgba(0,0,0,0)").mx-auto
                v-tabs-items(v-model="currentScreen" :touchless="true")
                    v-tab-item
                      PasteLinks(@click:next="goToNextScreen")
                    
                    v-tab-item
                      AddIntro(@click:next="goToNextScreen", @click:back="goToPreviousScreen")
                    
                    v-tab-item
                      AddNotes(@click:next="goToNextScreen", @click:back="goToPreviousScreen")
                    
                    v-tab-item
                      AddBranding(@click:next="handleGenerate", @click:back="goToPreviousScreen")
                        
                    v-tab-item
                     Generating(@click:next="goToNextScreen")
                    
                    v-tab-item
                      Sharing

</template>

<script>
import { get, sync, call } from "vuex-pathify";

import ProposalMakerWrapper from "@/components/CRM/Proposals/Maker/ProposalMakerWrapper";
import TabCard from "@/components/CRM/Proposals/Maker/TabCard";
import PasteLinks from "@/components/CRM/Proposals/Maker/Sections/PasteLinks.vue";
import AddIntro from "@/components/CRM/Proposals/Maker/Sections/AddIntro.vue";
import AddNotes from "@/components/CRM/Proposals/Maker/Sections/AddNotes.vue";
import AddBranding from "@/components/CRM/Proposals/Maker/Sections/AddBranding.vue";
import Generating from "@/components/CRM/Proposals/Maker/Sections/Generating.vue";
import Sharing from "@/components/CRM/Proposals/Maker/Sections/Sharing.vue";

export default {
  name: "ProposalMaker",
  components: {
    ProposalMakerWrapper,
    TabCard,
    PasteLinks,
    AddIntro,
    AddNotes,
    AddBranding,
    Generating,
    Sharing
  },
  watch: {
    links(newVal, oldVal) {
      console.log("LINKS CHANGED", newVal, oldVal);
      if (oldVal !== newVal)
        newVal.forEach(url => this.addReadableListing({ url }));
    }
  },
  data() {
    return {};
  },
  mounted() {
    this.initCreateProposal();
    if (
      !this.activeLead ||
      !this.activeLead.basicInfo ||
      !this.activeLead.basicInfo.firstName
    ) {
      this.$router.push("/crm/Leads");
      console.error(
        "Cannot CreateProposal because no activeLead id was bound, so we don't know who we're creating the proposal for"
      );
    }
  },
  computed: {
    ...sync({
      currentStep: "ProposalMaker/currentStep",
      currentScreen: "ProposalMaker/currentScreen"
    }),
    ...get({
      links: "ProposalMaker/links",
      readableListings: "ProposalMaker/readableListings",
      activeLead: "Leads/activeLead"
    }),
    generating() {
      if (!this.links) return true;
      return (
        this.links.length > 0 &&
        this.links.length > this.readableListings.length
      );
    }
  },
  methods: {
    ...call({
      initCreateProposal: "ProposalMaker/initCreateProposal",
      addReadableListing: "Cleaner/addReadableListing",
      goToPreviousScreen: "ProposalMaker/goToPreviousScreen",
      goToNextScreen: "ProposalMaker/goToNextScreen",
      createProposal: "ProposalMaker/createProposal",
      bindReadableListings: "ProposalMaker/bindReadableListings"
    }),

    retryOnLongWait() {
      this.links.forEach(link => this.addReadableListing({ url: link }));
    },
    async handleGenerate() {
      this.goToNextScreen();
      this.bindReadableListings();
      await this.createProposal();

      setTimeout(() => {
        console.log("retrying to generate");
        // if still spinning after 7 seconds, try generating one more time
        if (this.generating) this.retryOnLongWait();
      }, 7000);
      setTimeout(() => {
        console.log("retrying for a second time to generate");
        // if still spinning after 7 seconds, try generating one more time
        if (this.generating) this.retryOnLongWait();
      }, 15000);
      setTimeout(() => {
        console.log("retrying for a third (last) time to generate");
        // if still spinning after 7 seconds, try generating one more time
        if (this.generating) this.retryOnLongWait();
      }, 30000);
    }
  }
};
</script>
