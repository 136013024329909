import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";

// import Vue from "vue";
// import { firestorePlugin } from "vuefire";

// const serialize = doc => {
//   const data = doc.data();
//   Object.defineProperty(data, "id", { value: doc.id });
//   Object.defineProperty(data, "_doc", { value: doc });
//   return data;
// };

// const options = { serialize };
// Vue.use(firestorePlugin, options);

export const firebaseConfig = {
  apiKey: "AIzaSyA7Ie7pief9yU6IhwJnsiEgyII7A_4HK-g",
  authDomain: "bulgaria-property.firebaseapp.com",
  databaseURL: "https://bulgaria-property.firebaseio.com",
  projectId: "bulgaria-property",
  storageBucket: "bulgaria-property.appspot.com",
  messagingSenderId: "537397109739",
  appId: "1:537397109739:web:29779aac2e65653d586fae"
};

firebase.initializeApp(firebaseConfig);

const initEmulators = async () => {
  console.log("initializing connections to emulator suite");
  firebase.auth().useEmulator("http://localhost:9099");
  firebase.functions().useEmulator("localhost", 5001);
  firebase.firestore().useEmulator("localhost", 8889);
};

const isDev = process.env.NODE_ENV !== "production";
if (isDev) initEmulators();

const db = firebase.firestore();

const { Timestamp, GeoPoint } = firebase.firestore;
export { Timestamp, GeoPoint };

export const Firebase = firebase;
export default db;
