<template lang="pug">
    v-container.no-leads-yet
        v-card(outlined).pa-5
            v-row()
                v-col(cols="12" md="6")
                    router-link(to="/crm/CreateLead")
                        v-img(src="@/assets/crm/add_leads.svg" :max-width="$vuetify.breakpoint.mdAndDown ? 250 : 500").mx-auto
                v-col(cols="12" md="6")
                    h2.mb-3 Start by adding your first lead
                    p
                        strong A lead is a person who is looking to buy a property. 
                    p You can send them proposals with property listings from your own agency or from partner agencies.

                    v-btn(x-large color="secondary" depressed to="/crm/CreateLead" block).mt-6
                        | Add first lead
                        v-icon.ml-2 mdi-plus-circle

                
    
</template>

<script>
export default {
  name: "NoLeadsYet"
};
</script>
