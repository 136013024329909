import Firebase from "firebase/app";
// import 'firebase/auth'
import db from "@/plugins/db.js";
import router from "@/router";
import { make } from "vuex-pathify";
// const { nanoid } = require('nanoid')

export const DEFAULT_BRANDING = {
  colors: {
    primary: "#222831",
    accent: "#fbd46d"
  },
  logo: "https://bgproperty.today/logo-app.png",
  url: "https://your-agency.example.com",
  name: "Agency",
  description:
    "See the latest property listings in Bansko, gathered every day from the top real estate agencies."
};

const DEFAULT_AGENCY = {
  name: "An Agency",
  branding: DEFAULT_BRANDING
};
const state = {
  userData: {},
  agencyData: {}
};

const getters = make.getters(state);

const mutations = {
  ...make.mutations(state)
};

const actions = {
  ...make.actions(["userData"]),
  createUser: async (context, { email, password }) => {
    return await Firebase.auth().createUserWithEmailAndPassword(
      email,
      password
    );
  },
  register: async ({ dispatch }, { agencyId, firstName, email, password }) => {
    if (agencyId && email && password && firstName) {
      // About to register user

      await dispatch("createUser", {
        email,
        password,
        displayName: firstName
      })
        .then(async ({ user }) => {
          const payload = {
            id: user.uid,
            agencyId,
            firstName,
            email,
            isAgent: true
          };

          await db
            .collection("users")
            .doc(user.uid)
            .set(payload);

          const agency = await (
            await db
              .collection("agencies")
              .doc(agencyId)
              .get()
          ).data();

          if (!agency || !agency.branding) {
            const newAgency = DEFAULT_AGENCY;
            await db
              .collection("agencies")
              .doc(agencyId)
              .set(newAgency);
          }
        })
        .catch(err => {
          console.error("Create user error", err);
          throw new Error("COULD_NOT_CREATE_FIREBASE_USER");
        });
    } else {
      throw new Error("AGENCY_DOES_NOT_EXIST");
    }
  },
  signIn: async ({ dispatch, commit }, { email, password }) => {
    try {
      return Firebase.auth()
        .signInWithEmailAndPassword(email, password)
        .then(async ({ user }) => {
          commit("SET_USER_DATA", {});
          return await dispatch("bindUserData", { id: user.uid });
        })
        .catch(error => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error("1-", errorCode, errorMessage);
          throw new Error(errorMessage);
        });
    } catch (err) {
      throw new Error("SIGN_IN_FAILURE");
    }
  },

  signOut: async ({ dispatch }) => {
    router.push("/login");
    Firebase.auth()
      .signOut()
      .then(async function() {
        await dispatch("setUserData", {});
      })
      .catch(error => {
        console.error(error);
      });
  },

  upsertUserData: async (context, payload) => {
    try {
      const thisUser =
        Firebase.auth().currentUser && Firebase.auth().currentUser.uid
          ? Firebase.auth().currentUser.uid
          : null;

      if (!thisUser) return "Oops, are you sure you're logged in right now?";

      const upsertUserData = Firebase.functions().httpsCallable(
        "upsertUserData"
      );
      const upsertRes = await upsertUserData({ ...payload, userId: thisUser });
      console.log("1- upsertRes", upsertRes);
      if (upsertRes && upsertRes.data && upsertRes.data._writeTime) {
        return true;
      } else throw new Error("Unknown error during user data upsert");
    } catch (err) {
      console.error(err);
      return "There was a strange error and unfortunately your updates were NOT saved. Please contact our support team.";
    }
  },
  upsertBranding: async ({ getters }, payload) => {
    try {
      const thisUser =
        Firebase.auth().currentUser && Firebase.auth().currentUser.uid
          ? Firebase.auth().currentUser.uid
          : null;

      if (!thisUser) return "Oops, are you sure you're logged in right now?";

      if (getters.userData && getters.userData.isOwner) {
        const upsertBranding = Firebase.functions().httpsCallable(
          "upsertBranding"
        );
        const upsertRes = await upsertBranding({
          ...payload,
          userId: thisUser
        });
        if (upsertRes && upsertRes.data && upsertRes.data._writeTime) {
          console.log("after dispatch branding is", getters.agencyData);
          return true;
        } else throw new Error("Unknown error during user data upsert");
      } else
        throw new Error(
          "Sorry, you don't have editing permission to update your agency's branding. Please contact our support team."
        );
    } catch (err) {
      console.error(err);
      return "There was a strange error and unfortunately your updates were NOT saved. Please contact our support team.";
    }
  },
  bindUserData: async ({ commit, dispatch }, { id = null }) => {
    console.log("binding user data...");
    return new Promise((resolve, reject) => {
      const query = db.collection("users").doc(id);

      query.onSnapshot(
        async querySnapshot => {
          const obj = querySnapshot;
          const userData = { ...obj.data(), id: querySnapshot.id };
          commit("SET_USER_DATA", userData);

          await dispatch("bindAgencyData", { agencyId: userData.agencyId });
          resolve(userData);
        },
        err => {
          console.error(`Encountered error: ${err}`);
          reject(err);
        }
      );
    });
  },
  bindAgencyData: async ({ commit }, { agencyId }) => {
    console.log("binding agency data for agency id", agencyId);
    return new Promise((resolve, reject) => {
      const query = db.collection("agencies").doc(agencyId);

      query.onSnapshot(
        async querySnapshot => {
          const obj = querySnapshot;
          const agencyData = { ...obj.data(), id: querySnapshot.id };
          commit("SET_AGENCY_DATA", agencyData);

          resolve(agencyData);
        },
        err => {
          console.error(`Encountered error: ${err}`);
          reject(err);
        }
      );
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
