<template lang="pug">
    .lead-details(v-if="activeLead").grey.lighten-4.pb-12
      v-container.basic-info(v-if="activeLead")
        v-row(align="start")
          v-col(cols="12" xl="6")
            v-card(outlined)
              .top-bar.primary.lighten-2
                v-icon(color="grey lighten-3") mdi-information-variant
              .pa-5
                h2.text-center.mb-6.primary--text Basic Lead Details
                EditBasicInfo(buttonText="Save Basic Info" :showCancel="false" outlined)
          v-col(cols="12" xl="6")
            v-card(outlined)
              .top-bar.primary.lighten-5
                v-icon(color="grey lighten-3") mdi-information-variant
              .pa-5
                h2.text-center.mb-6.primary--text.text--lighten-3 Extra Lead Details
                EditAdvancedInfo(buttonText="Save Extra Info" :showCancel="false" outlined)

          v-col(cols="12")
            v-card(outlined)
              .top-bar.primary
                v-icon(color="grey") mdi-information-variant
              .pa-5
                h2.text-center.mb-6.primary--text.text--lighten-3 Proposals
                ViewProposals(v-if="proposals && proposals.length > 0" :proposals="proposals")
                v-card(v-else outlined max-width="750").mx-auto.pa-3.pa-6.text-center
                  v-icon(size="4rem" color="primary lighten-4").mb-3 mdi-information
                  br
                  | Send {{activeLeadName}} one or more property proposals, and you will see them listed here.

                 
              
        
    
</template>

<script>
import EditBasicInfo from "@/components/CRM/Leads/EditBasicInfo.vue";
import EditAdvancedInfo from "@/components/CRM/Leads/EditAdvancedInfo.vue";
import ViewProposals from "@/components/CRM/Leads/ViewProposals.vue";

import { get, call } from "vuex-pathify";
export default {
  name: "LeadDetails",
  components: { EditBasicInfo, EditAdvancedInfo, ViewProposals },
  props: {},
  watch: {
    $route() {
      this.init();
    }
  },
  data() {
    return {
      proposals: []
    };
  },
  computed: {
    ...get({
      activeLead: "Leads/activeLead",
      activeLeadName: "Leads/activeLeadName"
    })
  },
  mounted() {
    console.log("Lead details mounted");
    this.init();
  },
  methods: {
    ...call({
      bindActiveLead: "Leads/bindActiveLead",
      getProposalsForLead: "Leads/getProposalsForLead"
    }),
    async init() {
      console.log("binding...");
      const { id } = this.$route.query;

      if (id) {
        await this.bindActiveLead({ leadId: id });
        console.log("in init() About to get proposals for lead....");
        this.proposals = await this.getProposalsForLead();

        console.log("ultimately activeLead", this.activeLead);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.max-width {
  max-width: 900px;
}
</style>
