import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
// import Firebase from "firebase/app";
import { Firebase } from "@/plugins/db.js";

import Home from "../views/Home.vue";
Vue.use(VueRouter);

const aggregator = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/hire-dima",
    name: "HireDima",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "static" */ "../views/HireDima.vue")
  },
  {
    path: "/faq",
    name: "Faq",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "static" */ "../views/Faq.vue")
  },
  {
    path: "/privacy",
    name: "Privacy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "static" */ "../views/Privacy.vue")
  },

  {
    path: "/search",
    name: "Search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/Search.vue")
  }
];

const cleaner = [
  // {
  //   path: "/cleaner",
  //   name: "Cleaner",
  //   component: () =>
  //     import(/* webpackChunkName: "cleaner" */ "../views/Cleaner.vue")
  // }
];

const proposals = [
  {
    path: "/proposals/:agencyId/:id",
    name: "Proposal",
    meta: { layout: "app" },
    component: () =>
      import(/* webpackChunkName: "proposals" */ "../views/Proposal.vue")
  }
];

const account = [
  {
    path: "/login",
    name: "Login",
    meta: { layout: "app" },
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/CRM/Account/Login.vue")
  },
  {
    path: "/register",
    name: "Register",
    meta: { layout: "app" },
    component: () =>
      import(
        /* webpackChunkName: "account" */ "../views/CRM/Account/Register.vue"
      )
  }
];

const crm = [
  {
    path: "/app",
    redirect: "/crm/Leads"
  },
  {
    path: "/crm",
    redirect: "/crm/Leads"
  },
  {
    path: "/crm/:page",
    meta: { layout: "app", requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "crm" */ "../views/CRM/Dashboard/Dashboard.vue"
      )
  }
];

const fourOhFour = [
  {
    path: "*",
    redirect: "/404"
  },
  {
    path: "/404",
    component: () =>
      import(/* webpackChunkName: "PageNotFound" */ "../views/PageNotFound.vue")
  }
];
const routes = [
  ...aggregator,
  ...cleaner,
  ...proposals,
  ...account,
  ...crm,
  ...fourOhFour
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

const loginStatus = async () => {
  return new Promise((resolve, reject) => {
    try {
      const isLoggedIn =
        store &&
        store.getters &&
        store.getters["User/userData"] &&
        store.getters["User/userData"].id
          ? store.getters["User/userData"].id
          : null;

      Firebase.auth().onAuthStateChanged(user => {
        if (user && user.uid) {
          resolve({ firebaseId: user.uid, isLoggedIn: !!isLoggedIn });
        } else {
          resolve({ firebaseId: null, isLoggedIn: !!isLoggedIn });
        }
      });
    } catch (err) {
      console.error(err);
      reject();
    }
  });
};

const doLoginUser = async id =>
  await store.dispatch("User/bindUserData", { id });

router.beforeEach(async (to, from, next) => {
  if (to.meta && to.meta.layout && to.meta.layout === "app") {
    store.commit("SET_LAYOUT", to.meta.layout);
  } else {
    store.commit("SET_LAYOUT", "default");
  }

  const { isLoggedIn, firebaseId } = await loginStatus();

  // console.log(
  //   "in router before each, firebaseId = ",
  //   firebaseId,
  //   "isLoggedIn",
  //   isLoggedIn,
  //   "Firebase",
  //   Firebase
  // );

  if (firebaseId && !isLoggedIn) {
    await doLoginUser(firebaseId);
  }

  const isNowLoggedIn = (await loginStatus()).isLoggedIn;

  // no query params, so either show page or redirect to login
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  if (requiresAuth && !isNowLoggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
