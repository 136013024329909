import Firebase from "firebase/app";
import "firebase/auth";
import db from "@/plugins/db.js";
import { make } from "vuex-pathify";
import { isValidUrl } from "@/helpers";

import { DEFAULT_BRANDING } from "./User";

import sha1 from "sha1";

const DEFAULT_OPTIONS = [
  "includeIntro",
  "writeNotes",
  "includeBranding",
  "expiresIn",
  "generating"
];

const state = {
  screens: {
    enterLinks: 0,
    includeIntro: 1,
    writeNotes: 2,
    includeBranding: 3,
    generating: 4,
    share: 5
  },
  buttonError: "",
  intro: "",
  currentStep: 1,
  currentScreen: 0,
  strLinks: "",
  // strLinks: `https://bower-properties.com/for-sale/property/918-rs1019a-st-ivan-spa-complex-bansko
  // https://excelpropertybulgaria.com/property/3982/stylishly-furnished-one-bedroom-apartment-located-in-a-spa-complex-300-meters-from-the-ski-lift-in-bansko.html
  // https://banskorealty.com/property/82`,
  notes: [],
  expiresInDays: 7,
  branding: DEFAULT_BRANDING,
  selectedOptions: DEFAULT_OPTIONS,
  optionsList: [
    {
      text: "I want to include a personal introduction",
      value: "includeIntro"
    },
    {
      text: "I want to write notes about these properties",
      value: "writeNotes"
    },
    {
      text: "I want to include my logo + branding",
      value: "includeBranding",
      disabled: true
    },
    {
      text: "Expires in 7 days",
      value: "expiresIn",
      disabled: true
    }
  ],
  proposalLink: "",
  readableListings: []
};

const getters = {
  links: (state, getters, rootGetters) => {
    if (!getters.strLinks || getters.strLinks.length < 1) return [];
    const lines = getters.strLinks.split("\n");

    const linkIsSupported = link =>
      rootGetters.Cleaner.supportedSites.some(site =>
        link.toLowerCase().includes(site.url)
      );

    return lines
      .map(line => line.trim())
      .filter(Boolean)
      .filter(isValidUrl)
      .filter(linkIsSupported);
  },
  ...make.getters(state)
};

const mutations = make.mutations(state);

const actions = {
  goToNextScreen: ({ commit, getters }) => {
    commit("SET_BUTTON_ERROR", "");

    const currentScreen = getters.currentScreen;
    const selectedOptions = getters.selectedOptions;
    const screens = Object.entries(getters.screens);

    const nextScreenIsFinish = currentScreen === screens.length - 2;

    const nextValidScreen = nextScreenIsFinish
      ? screens[screens.length - 1]
      : screens.find(screen => {
          const val = screen[0];
          const idx = screen[1];

          return idx > currentScreen && selectedOptions.includes(val);
        });

    console.log("nextValidScreen", nextValidScreen);

    if (!getters.links || getters.links.length < 1) {
      commit("SET_BUTTON_ERROR", "Oops, please paste in some links first");
    } else {
      commit("SET_CURRENT_SCREEN", nextValidScreen ? nextValidScreen[1] : 0);
      commit("SET_CURRENT_STEP", nextValidScreen ? getters.currentStep + 1 : 1);

      document.getElementById("top").scrollIntoView();
    }
  },
  goToPreviousScreen: ({ commit, getters }) => {
    const currentScreen = getters.currentScreen;
    const selectedOptions = getters.selectedOptions;
    const screens = Object.entries(getters.screens);

    const reversedScreens = [...screens].reverse();

    const prevValidScreen = reversedScreens.find(screen => {
      const val = screen[0];
      const idx = screen[1];

      return idx < currentScreen && selectedOptions.includes(val);
    });

    commit("SET_CURRENT_SCREEN", prevValidScreen ? prevValidScreen[1] : 0);
    commit("SET_CURRENT_STEP", prevValidScreen ? getters.currentStep - 1 : 1);
  },
  bindReadableListings: async ({ commit }) => {
    try {
      return new Promise((resolve, reject) => {
        const query = db.collection("readableListings");

        query.onSnapshot(
          async querySnapshot => {
            let docs = [];
            for (const doc of querySnapshot.docs) {
              docs.push({
                id: doc.id,
                ...doc.data()
              });
            }

            console.log("binding readableListings, got obj", docs);
            commit("SET_READABLE_LISTINGS", docs);
            resolve();
          },
          err => {
            console.log(`Encountered error: ${err}`);
            reject(err);
          }
        );
      });
    } catch (err) {
      console.error(err);
    }
  },
  waitForAllPropertiesToBeCreated: async ({ getters, dispatch }) => {
    return new Promise((resolve, reject) => {
      try {
        const MAX_ALLOWED_TRIES = 10;
        let thisTry = 0;
        const int = setInterval(async () => {
          thisTry++;
          if (thisTry <= MAX_ALLOWED_TRIES) {
            const properties = await dispatch("getPropertiesForLinks", {
              links: getters.links
            });
            if (properties.length === getters.links.length) {
              clearInterval(int);
              console.log("resolving");
              resolve();
            }
          } else {
            clearInterval(int);
            console.log("rejecting");
            reject();
          }
        }, 2000);
      } catch (err) {
        console.error(err);
        reject();
      }
    });
  },
  getPropertiesForLinks: async (context, { links }) => {
    const properties = [];
    for (const link of links) {
      const readablePageId = sha1(link);
      const property = await (
        await db
          .collection("readableListings")
          .doc(readablePageId)
          .get()
      ).data();

      properties.push(property);
    }

    return properties.filter(Boolean);
  },
  initCreateProposal: async ({ commit }) => {
    commit("SET_CURRENT_STEP", 1);
    commit("SET_CURRENT_SCREEN", 0);
    commit("SET_STR_LINKS", "");
    commit("SET_INTRO", "");
    commit("SET_NOTES", []);
    commit("SET_PROPOSAL_LINK", "");
  },
  createProposal: async ({ getters, commit, rootGetters }) => {
    const { links } = getters;
    const { intro, notes, expiresInDays } = getters;

    const { branding } = rootGetters["User/agencyData"];

    const expiration = new Date(
      Date.now() + expiresInDays * 24 * 60 * 60 * 1000
    );

    console.log("Creating proposal with expiration", expiration);

    const { id: leadId } = rootGetters["Leads/activeLead"];
    const {
      firstName,
      lastName,
      email,
      primaryPhone,
      secondaryPhone,
      contactNotes,
      agencyId
    } = rootGetters["User/userData"];

    console.log("rootgetters", rootGetters);

    const agent = {
      firstName,
      lastName,
      email,
      primaryPhone,
      secondaryPhone,
      contactNotes
    };
    if (!agent || !agent.firstName)
      throw new Error(
        "Could not create property propossal because agent information was missing or invalid"
      );

    console.log("About to create proposal with agencyId", agencyId);
    const createProposal = Firebase.functions().httpsCallable("createProposal");
    const res = await createProposal({
      leadId,
      agencyId,
      agent,
      intro,
      links,
      notes,
      branding,
      expiration: JSON.stringify(expiration)
    });

    const proposalId = res.data;

    console.log("proposalId", proposalId);

    const proposalLink = `${rootGetters.hostname}/proposals/${agencyId}/${proposalId}`;

    console.log("proposalLink", proposalLink);
    commit("SET_PROPOSAL_LINK", proposalLink);
  },
  init: ({ commit }) => {
    commit("SET_STR_LINKS", "");
    commit("SET_SELECTED_OPTIONS", DEFAULT_OPTIONS);
    commit("SET_NOTES", []);
    commit("SET_CURRENT_SCREEN", 0);
    commit("SET_CURRENT_STEP", 1);
    commit("SET_PROPOSAL_LINK", "");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
