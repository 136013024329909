<template lang="pug">
  .tab-card
    v-card.pa-3.pa-md-6.pa-lg-8.pa-xl-12(flat).mx-auto
        .header.d-flex.justify-space-between.align-center.body-1.primary--text.text--secondary.mb-3
          .step(v-if="currentStep") Step {{currentStep}}
          .lead-name(v-if="activeLeadName") {{activeLeadName}}
        h2.display-2.font-weight-bold.primary--text {{title}}
        .body-1.mt-2.primary--text.text--secondary(v-if="subtitle") {{subtitle}}
        slot
        .buttons.d-flex.mt-5.align-baseline
            v-btn(v-if="showBackButton", @click="$emit('click:back')" color="primary lighten-3" x-large plain).mr-md-1 Back
            BtnNext(v-if="showNextButton", :text="nextButtonText", @click="$emit('click:next')").mr-2
            v-alert(type="error" outlined dense v-if="buttonError") {{buttonError}}
    
    
    v-card(v-if="$slots.footer", color="white" flat).grey.lighten-4.pa-3.mt-12
      slot(name="footer")

</template>

<script>
import { get } from "vuex-pathify";
import BtnNext from "@/components/Proposals/Maker/BtnNext";
export default {
  name: "TabCard",
  components: { BtnNext },
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, default: null },
    buttonError: { type: String, default: null },
    showNextButton: { type: Boolean, default: false },
    showBackButton: { type: Boolean, default: false },
    nextButtonText: { type: String, default: "Next Step" }
  },
  computed: {
    ...get({
      currentStep: "ProposalMaker/currentStep",
      activeLeadName: "Leads/activeLeadName"
    })
  }
};
</script>
