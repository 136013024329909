<template lang="pug">
    .maker-section--paste-links.grey.lighten-3
        TabCard(title="Paste in a list of links", subtitle="One property link per line please", showNextButton, :buttonError="buttonError", @click:next="handleClickNext")
            v-textarea(outlined hide-details :placeholder="placeholder" v-model="strLinksDirty").grey.lighten-3.mt-6
            MakerOptions
            
            template(#footer)
                h2.mb-4 Sites this tool supports:

                v-row(dense)
                    v-col(cols="12" md="6" v-for="company in supportedSites", :key="company.url")
                        v-icon.mr-2(color="accent darken-5") mdi-circle-medium
                        a(:href="company.url" target="_blank" rel="noopener") {{company.name}}
</template>

<script>
import { get, sync } from "vuex-pathify";
import TabCard from "@/components/CRM/Proposals/Maker/TabCard";
import MakerOptions from "@/components/CRM/Proposals/Maker/MakerOptions";
export default {
  name: "MakerSectionPasteLinks",
  components: { TabCard, MakerOptions },
  data() {
    return {
      strLinksDirty: ""
    };
  },
  computed: {
    ...get({
      links: "ProposalMaker/links",
      supportedSites: "Cleaner/supportedSites"
    }),
    ...sync({
      strLinks: "ProposalMaker/strLinks",
      buttonError: "ProposalMaker/buttonError"
    }),
    placeholder() {
      const sampleLinks = [
        "https://bower-properties.com/for-sale/property/918-rs1019a-st-ivan-spa-complex-bansko",
        "https://excelpropertybulgaria.com/property/3982/stylishly-furnished-one-bedroom-apartment-located-in-a-spa-complex-300-meters-from-the-ski-lift-in-bansko.html",
        "https://banskorealty.com/property/82"
      ];

      return sampleLinks.join("\n");
    }
  },
  methods: {
    handleClickNext() {
      this.buttonError = null;
      this.strLinks = this.strLinksDirty;

      console.log("when clicking next, links", this.links);
      this.$nextTick(() => {
        if (this.links && this.links.length > 0) {
          this.$emit("click:next");
        } else {
          this.buttonError = "Oops, please paste in some links first ";
        }
      });
    }
  }
};
</script>
