<template lang="pug">
    .edit-basic-info-lead
      .d-flex.flex-column.flex-md-row
        v-text-field(v-model="basicInfo.firstName", outlined background-color="white" placeholder="James" label="First Name").font-weight-bold.mr-md-4
        v-text-field(v-model="basicInfo.lastName", outlined background-color="white" placeholder="Smith" label="Last Name").font-weight-bold
      .d-flex.flex-column.flex-md-row
        v-text-field(v-model="basicInfo.email",outlined background-color="white" placeholder="james.smith@example.com" label="Email").mr-md-4
        v-text-field(v-model="basicInfo.phonePrimary",outlined background-color="white" placeholder="+44 1234-5678" label="Primary Phone")
      
      .d-flex.flex-column.flex-md-row
        v-select(:backgroundColor="stageOptions.find(itm=>itm.value===basicInfo.stage).color", v-model="basicInfo.stage", label="Current stage", outlined background-color="white" :items="stageOptions")
          template(v-slot:item="{ item }")
            span(v-if="item.text") {{item.text}}
            v-divider(v-else)
      
      v-btn(:depressed="depressed", :outlined="outlined" :color="button.color" block x-large :loading="loading", @click="handleClick") {{button.text}}
      v-alert(type="error" outlined border="left" v-if="errorMessage").my-3 {{errorMessage}}
      .text-center(v-if="showCancel")
        v-btn(text color="accent" to="/crm/Leads").mt-2 Cancel
</template>

<script>
import { get, call, sync } from "vuex-pathify";
export default {
  name: "LeadEditBasicInfo",
  props: {
    isNewLead: { type: Boolean, default: false },
    buttonText: { type: String, default: "Save info" },
    showCancel: { type: Boolean, default: true },
    depressed: { type: Boolean, default: true },
    outlined: { type: Boolean, default: false },
    onSaveGoBackToLeadsList: { type: Boolean, default: false }
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      button: {
        text: "",
        color: "primary"
      }
    };
  },
  mounted() {
    this.button.text = this.buttonText;
  },
  computed: {
    ...sync({
      basicInfo: "Leads/dirtyLead@basicInfo"
    }),
    ...get({
      stageOptions: "Leads/stageOptions"
    })
  },
  methods: {
    ...call({
      upsertLead: "Leads/upsertLead"
    }),

    async handleClick() {
      console.log("handling click...");
      try {
        this.loading = true;
        this.errorMessage = null;
        const res = await this.upsertLead({
          isNewLead: this.isNewLead,
          basicInfo: this.basicInfo
        });
        console.log("got res", res);
        if (res) {
          this.button.text = "Saved";
          this.button.color = "success";
          setTimeout(() => {
            this.button.text = this.buttonText;
            this.button.color = "primary";
          }, 2000);
          if (this.onSaveGoBackToLeadsList)
            this.$router.push({ path: "/crm/Leads" });
        } else {
          this.errorMessage = res;
        }
      } catch (err) {
        this.errorMessage = err;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
