<template lang="pug">
    .lead-stage-indicator
      v-tooltip(left)
        template(v-slot:activator="{ on, attrs }")
          v-btn(fab elevation="1", small :color="color" @click="showDialogExplainLeadStages = true")
            v-icon(v-on="on" color="white") {{icon}}
        span.text-capitalize {{stage.toLowerCase()}} Lead
</template>

<script>
import { get, sync } from "vuex-pathify";
export default {
  name: "LeadStageIndicator",
  props: { stage: { type: String, required: true } },
  computed: {
    ...get({ stageOptions: "Leads/stageOptions" }),
    ...sync({
      showDialogExplainLeadStages: "CrmNav/dialogs@showDialogExplainLeadStages"
    }),
    icon() {
      if (!this.stage) return "mdi-circle-small";
      return this.stageOptions.find(itm => itm.value === this.stage).icon;
    },
    color() {
      if (!this.stage) return "grey";
      return this.stageOptions.find(itm => itm.value === this.stage).color;
    }
  }
};
</script>
