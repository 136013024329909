// import Firebase from "firebase/app";
// import store from "../index";
import router from "@/router";
import "firebase/auth";
import db from "@/plugins/db.js";
import { make } from "vuex-pathify";
import { isValidUrl } from "@/helpers";
import { waitForElemToExist } from "@/helpers";

const state = {
  activeProposal: null,
  activeProperty: null,
  activePropertyId: 0
};

const getters = {
  links: (state, getters, rootGetters) => {
    if (!getters.maker.strLinks || getters.maker.strLinks.length < 1) return [];
    const lines = getters.maker.strLinks.split("\n");

    const linkIsSupported = link =>
      rootGetters.Cleaner.supportedSites.some(site =>
        link.toLowerCase().includes(site.url)
      );

    return lines
      .map(line => line.trim())
      .filter(Boolean)
      .filter(isValidUrl)
      .filter(linkIsSupported);
  },
  ...make.getters(state)
};

const mutations = make.mutations(state);

const actions = {
  setActiveProposal: async ({ commit, dispatch }, { agency, id }) => {
    try {
      if (!id) throw new Error("You must provide a proposal id to look up.");
      if (!agency) throw new Error("You must provide an agency id to look up.");

      const doc = await (
        await db
          .collection("agencies")
          .doc(agency)
          .collection("proposals")
          .doc(id)
          .get()
      ).data();

      if (!doc) throw new Error("Proposal not found or link is invalid");

      const properties = await dispatch(
        "ProposalMaker/getPropertiesForLinks",
        {
          links: doc.links
        },
        { root: true }
      );

      commit("SET_ACTIVE_PROPOSAL", { ...doc, properties });

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  changeActivePropertyTo: async ({ commit, dispatch, getters }, id) => {
    if (id == null)
      throw new Error("You must provide a property id to change to");

    if (getters.activeProperty) {
      //   Leaving a previous property
      await dispatch(
        "Analytics/fireEvent",
        {
          source: location.href,
          type: "LEAVE_PROPERTY",
          value: getters.activeProperty.url
        },
        { root: true }
      );
    }

    if (
      !getters.activeProposal ||
      !getters.activeProposal.properties ||
      getters.activeProposal.properties.length < 1
    )
      throw new Error(
        "There was a problem changing to any property id, since it looks like there are no properties at all for this proposal?"
      );

    const property = getters.activeProposal.properties[id];
    await dispatch(
      "Analytics/fireEvent",
      {
        source: location.href,
        type: "ENTER_PROPERTY",
        value: property.url
      },
      { root: true }
    );

    if (router.currentRoute.query.property - 1 !== id) {
      router.replace({
        query: { property: id + 1 }
      });
    }

    await waitForElemToExist("#content-top");
    document.getElementById("content-top").scrollIntoView();
    commit("SET_ACTIVE_PROPERTY_ID", id);
    commit("SET_ACTIVE_PROPERTY", property);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
