import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// import masonry from "./plugins/masonry";
import ago from "s-ago";

Vue.config.productionTip = false;

Vue.filter("asDate", function(dt) {
  const options = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric"
    // hour: "numeric",
    // minute: "numeric"
  };

  const res = dt.toLocaleString("en-GB", options);
  return res;
});
Vue.filter("asAgo", function(dt) {
  return ago(dt);
});
Vue.filter("asTime", function(dt) {
  const options = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  };

  const res = dt.toLocaleString("en-GB", options);
  return res;
});

Vue.filter("cleanDbText", str => {
  if (!str || str.length < 1) return str;
  return str
    .split("...read more")
    .join("...")
    .split("&nbsp;")
    .join(" ")
    .split("   ")
    .join(" | ")
    .split("�")
    .join("");
});

Vue.filter("asCurrency", str => {
  return parseInt(str).toLocaleString("en-GB");
});

new Vue({
  router,
  store,
  vuetify,
  // masonry,
  render: h => h(App)
}).$mount("#app");
