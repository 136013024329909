<template lang="pug">
    .listings
        template(v-if="!listings || listings.length < 1")
            v-row
                v-col(cols="12" md="6" v-for="itm in 12", :key="'skeleton-card-' + itm")
                    v-skeleton-loader(type="card")

        template(v-else)
            v-row.masonry
                v-col(class="child" cols="12" md="6", v-for="listing in listings", :key="listing.id")
                    ListingCard(:listing="listing" @load='doMasonry')

            v-row.mt-12
                v-col(cols="12")
                    template(v-if="noMoreListings")
                        v-card.pa-5(color="grey lighten-4" flat).text-center
                            p.mb-0.font-weight-bold That's it :-) We have no more listings like this. Try adjusting your search filters (at the bottom of this page).
                    template(v-else)
                        Pagination(@click="updateListings")
</template>

<script>
// import Masonry from "masonry-layout";
import { get, call } from "vuex-pathify";
const ListingCard = () => import("@/components/ListingCard.vue");
const Pagination = () => import("@/components/Pagination.vue");

export default {
  name: "Listings",
  components: { ListingCard, Pagination },
  data() {
    return {
      masonry: null
    };
  },
  created() {
    // this.init();
    this.getListings();
  },
  mounted() {},
  computed: {
    ...get(["priceFilter", "listings", "noMoreListings"])
  },
  methods: {
    ...call({ getListings: "getListings" }),
    async updateListings() {
      await this.getListings();
      this.doMasonry();
    },
    doMasonry() {
      //   if (!this.masonry) {
      //     setTimeout(() => {
      //       this.masonry = new Masonry(".masonry", {
      //         // options
      //         itemSelector: "[class*='col-']"
      //       });
      //     }, 1500);
      //   } else {
      //     this.masonry.reloadItems();
      //     this.masonry.layout();
      //   }
    }
  }
};
</script>
