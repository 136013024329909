<template lang="pug">
    v-container#analytics.analytics(fluid :class="{'pa-12': !dense, 'primary darken-2': !dense}").pa-0
      v-card(outlined).pa-5
        v-alert(v-if="!events || events.length < 1", type="info" color="primary" outlined border="left" :width="$vuetify.breakpoint.lgAndUp ? 900 : null").mt-3.mx-auto
          p
            b Your buyer hasn't opened this proposal yet. 
            | When they do, you'll see here detailed results about which properties they viewed, for how long, which images they clicked, etc
          p.mb-0 These results update in real time- no need to reload the page.
        Events(v-else)
</template>

<script>
import { get, call } from "vuex-pathify";
import Counts from "@/components/Proposals/Analytics/Counts.vue";
import Events from "@/components/Proposals/Analytics/Events.vue";
export default {
  name: "Analytics",
  components: { Counts, Events },
  props: {
    dense: { type: Boolean, default: false }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...get({
      counts: "Analytics/counts",
      events: "Analytics/events",
      activeProposal: "ProposalViewer/activeProposal"
    })
  },
  methods: {
    ...call({ bindAnalytics: "Analytics/bindAnalytics" }),
    init() {
      if (!this.counts) this.bindAnalytics("counts");

      if (!this.events) this.bindAnalytics("events");
    }
  }
};
</script>
