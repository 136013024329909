<template lang="pug">
    .events
      //- pre.debug {{event}}
      v-card(v-for="event, idx in events", :key="idx + event.created", outlined max-width="900").pa-1.pa-md-4.mb-3.mx-auto
        v-row
          v-col(cols="12" lg="6")
            v-row(align="center" justify="center")
              v-col(cols="1" md="2").text-center
                v-badge(:color="prettyColor(event.type, event.value)", :content="prettyIcon(event.type, event.value).badge", :value="event.type.includes('COUNT_')", :offset-x="10", :offset-y="10")
                  v-btn(icon to="/crm/Leads")
                    v-icon(:color="prettyColor(event.type)") {{prettyIcon(event.type, event.value).icon}}
              v-spacer(v-if="$vuetify.breakpoint.smAndDown")
              v-col(cols="10" md="10")
                .d-flex.flex-wrap
                  .firstname.font-weight-bold {{event.lead.basicInfo.firstName}}
                  .lastname.ml-1 {{event.lead.basicInfo.lastName}}
                  .type.ml-1(v-html="prettyType({type: event.type, value: event.value, source: event.source})")
          
          v-col(cols="12" lg="6")
            v-row(align="center" justify="center")
              v-col(cols="1" md="2").text-center
                v-btn(icon :href="event.source" target="_blank" rel="noopener")
                  v-icon mdi-open-in-new
              v-spacer(v-if="$vuetify.breakpoint.smAndDown")
              v-col(cols="10" md="10")
                v-tooltip(top)
                  template(v-slot:activator="{ on, attrs }")
                    span(v-on="on") {{timeAgo(event.created)}}
                  span {{event.created | asTime}}
      //- pre.debug events {{events}}
</template>

<script>
import { get } from "vuex-pathify";
import ago from "s-ago";
export default {
  name: "Metrics",
  props: {
    events: { type: Array, required: true }
  },
  computed: {
    ...get({
      getLeadById: "Leads/getLeadById",
      prettyColor: "Insights/prettyColor",
      prettyType: "Insights/prettyType",
      prettyIcon: "Insights/prettyIcon"
    })
  },
  methods: {
    timeAgo(dt) {
      return ago(dt);
    }
  }
};
</script>
