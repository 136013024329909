<template lang="pug">
    .maker-section--generating
      TabCard(v-if="generating", title="We are generating your proposal...", subtitle="Please wait a few moments.", key="generating")
        .mt-6
          v-row(v-for="link in links", :key="link")
            v-col(cols="1")
              v-btn(icon :loading="!getLinkTitle(link)" small)
                v-icon(color="success") mdi-check
            v-col(cols="11")
              span(:class="getLinkTitle(link) ? 'success--text' : 'accent--text'") {{getLinkTitle(link) ? getLinkTitle(link) : link}}
        
      TabCard(v-else title="Done!", showNextButton, nextButtonText="Get Link", subtitle="We prepared a proposal link that you can share with your buyer via email, WhatsApp, Messenger, etc?", @click:next="$emit('click:next')", key="finish")
</template>

<script>
import { get, sync } from "vuex-pathify";
import TabCard from "@/components/CRM/Proposals/Maker/TabCard";
import AgencyBrandingPreview from "@/components/CRM/Account/AgencyBrandingPreview.vue";
export default {
  name: "MakerSectionGenerating",
  components: { TabCard, AgencyBrandingPreview },
  data() {
    return {};
  },
  computed: {
    ...get({
      readableListings: "ProposalMaker/readableListings",
      links: "ProposalMaker/links"
    }),
    ...sync({}),
    generating() {
      if (!this.links) return true;
      return (
        this.links.length > 0 &&
        this.links.length > this.readableListings.length
      );
    }
  },
  methods: {
    getLinkTitle(url) {
      const foundListing = this.readableListings.find(
        listing => listing.url.toLowerCase() === url
      );
      return foundListing ? foundListing.title : null;
    }
  }
};
</script>
