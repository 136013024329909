<template lang="pug">
    .maker-section--add-branding
      TabCard(title="Add your branding", showNextButton, showBackButton, nextButtonText="Create Proposal", :buttonError="buttonError", @click:next="$emit('click:next')", @click:back="$emit('click:back')")
        p.my-4 This is how your Agency Branding will appear on the finished proposal...
        AgencyBrandingPreview
</template>

<script>
import { get, sync } from "vuex-pathify";
import TabCard from "@/components/CRM/Proposals/Maker/TabCard";
import AgencyBrandingPreview from "@/components/CRM/Account/AgencyBrandingPreview.vue";
export default {
  name: "MakerSectionPasteLinks",
  components: { TabCard, AgencyBrandingPreview },
  data() {
    return {};
  },
  computed: {
    ...get({
      links: "ProposalMaker/links",
      buttonError: "ProposalMaker/buttonError"
    }),
    ...sync({ notes: "ProposalMaker/notes" })
  }
};
</script>
