var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"events"},[_c('h2',{staticClass:"text-center display-1 mb-4"},[_vm._v("Proposal Analytics")]),(_vm.events && _vm.counts && _vm.events.length > 0 && _vm.counts.length > 0)?_c('v-data-table',{attrs:{"items":_vm.events,"group-by":"value","headers":_vm.headers,"sortBy":"created","sortDesc":"","hide-default-header":"","items-per-page":25},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
return [_c('td',{staticClass:"text-start",attrs:{"colspan":"3"}},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.calcViews(items[0].property ? items[0].value : items[0].source))+" Views: ")])]),_c('span',{attrs:{"title":_vm.prettySource(items)}},[_vm._v(_vm._s(_vm.prettySource(items)))])])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type==='ENTER_PROPOSAL')?_c('v-icon',[_vm._v("mdi-source-commit-start")]):(item.type==='ENTER_PROPERTY')?_c('v-icon',[_vm._v("mdi-arrow-right-bold")]):(item.type==='LEAVE_PROPERTY')?_c('v-icon',[_vm._v("mdi-arrow-top-left")]):(item.type==='LEAVE_PROPOSAL')?_c('v-icon',[_vm._v("mdi-exit-to-app")]):(item.type==='VIEW_IMAGE')?_c('v-icon',[_vm._v("mdi-image-search-outline")]):_vm._e(),_c('span',{staticClass:"ml-1 text-capitalize"},[_vm._v(_vm._s(item.type.toLowerCase().split("_").join(" ")))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"label"},[_vm._v("Date")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.prettyDate(item.created)))])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [(item.property || item.type === 'VIEW_IMAGE')?[(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"label"},[_vm._v("Link")]):_vm._e(),_c('v-btn',{attrs:{"icon":"","href":item.property ? item.source : item.value,"target":"_blank","rel":"noopener"}},[_c('v-icon',[_vm._v("mdi-link")])],1)]:_vm._e()]}}],null,false,1202590977)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }