<template lang="pug">
    .proposals
      v-card.pa-3.pa-md-6.mb-5(color="grey lighten-4" flat v-for="proposal in proposals", :key="proposal.id")
        v-row(align="center" justify="center")
          v-col(cols="12" md="3", lg="2" :title="proposal.created.toDate() | asTime").font-weight-bold {{proposal.created.toDate() | asDate}}
          v-col(cols="12" md="9", lg="6")
            CopySharingLink(:proposalLink="proposalLink(proposal.id)")
        v-row(align="start", dense v-for="link, idx in proposal.links", :key="link")
          v-col(cols="1").text-right {{idx+1}} –
          v-col(cols="11") 
            a(:href="link", target="_blank", rel="noopener") {{link}}

        .mt-5
          v-btn(block v-if="!activeProposal || activeProposal.id !== proposal.id", depressed color="secondary" @click="handleViewAnalytics(proposal)")
            v-icon.mr-2 mdi-eye
            | View analytics...
          Analytics(v-if="activeProposal && activeProposal.id === proposal.id", dense)
</template>

<script>
import CopySharingLink from "@/components/CRM/Leads/Partials/CopySharingLink.vue";
import Analytics from "@/components/Proposals/Analytics/Analytics.vue";
import { get, call } from "vuex-pathify";
export default {
  name: "ViewProposals",
  components: { CopySharingLink, Analytics },
  props: {
    proposals: { type: Array, require: true }
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...get({ activeProposal: "ProposalViewer/activeProposal" })
  },
  methods: {
    ...call({ setActiveProposal: "ProposalViewer/setActiveProposal" }),
    proposalLink(id) {
      const proposal = this.proposals.find(itm => itm.id === id);
      return (
        location.protocol +
        "//" +
        location.host +
        "/proposals/" +
        proposal.agencyId +
        "/" +
        proposal.id
      );
    },
    async handleViewAnalytics(proposal) {
      console.log("about to view analytics for proposal", proposal);
      await this.setActiveProposal({
        agency: proposal.agencyId,
        id: proposal.id
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
