import Firebase from "firebase/app";
const { nanoid } = require("nanoid");
import db from "@/plugins/db.js";

export const onEnterLeave = ({ agencyId, proposalId, source, type, value }) => {
  const eventId = nanoid();
  db.collection("agencies")
    .doc(agencyId)
    .collection("proposals")
    .doc(proposalId)
    .collection("analytics")
    .doc("events")
    .set(
      {
        [eventId]: {
          created: new Date(),
          source,
          type,
          value
        }
      },
      { merge: true }
    );

  if (type.startsWith("ENTER_")) {
    const increment = Firebase.firestore.FieldValue.increment(1);
    db.collection("agencies")
      .doc(agencyId)
      .collection("proposals")
      .doc(proposalId)
      .collection("analytics")
      .doc("counts")
      .set(
        {
          [value]: {
            views: increment
          }
        },
        { merge: true }
      );
  }
};

export const onOtherEvents = async ({
  agencyId,
  proposalId,
  source,
  type,
  value
}) => {
  const eventId = nanoid();
  db.collection("agencies")
    .doc(agencyId)
    .collection("proposals")
    .doc(proposalId)
    .collection("analytics")
    .doc("events")
    .set(
      {
        [eventId]: {
          created: new Date(),
          source,
          type,
          value
        }
      },
      { merge: true }
    );
};
