<template lang="pug">
    .create-lead.pa-12
        v-container(fluid).text-center.mb-6
          .title.mb-3
            .text-h4 Insights
          p Get valuable insights on the 10 potential customers who most recently viewed your proposals
          
        v-container
            NoInsightsYet(v-if="done && !insights || insights.length < 1")
            
            template(v-else)
              Events(v-if="insights", :events="insights")
</template>

<script>
import Metrics from "@/components/CRM/Insights/Metrics.vue";
import Events from "@/components/CRM/Insights/Events.vue";
import NoInsightsYet from "@/components/CRM/Insights/Partials/NoInsightsYet.vue";
import { call, get } from "vuex-pathify";
export default {
  name: "Insights",
  components: { Events, Metrics, NoInsightsYet },
  data() {
    return {
      done: false,
      errorMessage: null
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...get({
      isRefreshing: "Insights/isRefreshing",
      insights: "Insights/insights"
    })
  },
  methods: {
    ...call({
      bindInsights: "Insights/bindInsights"
    }),
    async init() {
      await this.bindInsights();
      this.done = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
