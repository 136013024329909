<template lang="pug">
    .price-filter
        v-menu(max-height="50vh")
            template(v-slot:activator="{ on, attrs }")
                v-btn(v-on="on" :outlined="activeFilter !== 'PRICE'", :depressed="activeFilter === 'PRICE'" color="primary" :block="$vuetify.breakpoint.smAndDown")
                  v-icon.mr-2 mdi-cash-multiple
                  | {{priceFilter && priceFilter.text ? priceFilter.text : "Filter by Price"}}
            v-list
                v-list-item.text-capitalize(v-for="price, priceIdx in prices", :key="priceIdx" @click="setPriceFilter(price)") {{price.text}}

</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "PriceFilter",
  data() {
    return {
      prices: [
        { text: "under 20,000 €", amount1: 0, amount2: 20000 },
        {
          text: "20 ~ 30,000 €",
          amount1: 20000,
          amount2: 30000
        },
        {
          text: "30 ~ 40,000 €",
          amount1: 30000,
          amount2: 40000
        },
        {
          text: "40 ~ 50,000 €",
          amount1: 40000,
          amount2: 50000
        },
        {
          text: "50 ~ 80,000 €",
          amount1: 50000,
          amount2: 80000
        },
        {
          text: "80 ~ 100,000 €",
          amount1: 80000,
          amount2: 100000
        },
        {
          text: "100 ~ 200,000 €",
          amount1: 100000,
          amount2: 200000
        },
        {
          text: "200 ~ 500,000 €",
          amount1: 200000,
          amount2: 500000
        },
        {
          text: "over 500,000 €",
          amount1: 500000,
          amount2: 99999999
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["activeFilter", "priceFilter"])
  },
  methods: {
    ...mapActions(["setPriceFilter"])
  }
};
</script>
