<template lang="pug">
    .metrics
        pre.debug counts{{counts}}
</template>

<script>
export default {
  name: "Metrics",
  props: {
    counts: { type: Array, required: true }
  }
};
</script>
