<template lang="pug">

  .add-new-lead.fill-height
      router-link(to="/crm/CreateLead").text-decoration-none
        v-card(tile outlined color="grey lighten-4" height="100%").d-flex.flex-column.justify-space-between
          v-sheet(color="success" height="20px" width="100%")
          v-card-text
            .text-right
              v-btn(fab elevation="1" small color="success")
                v-icon(large color="white") mdi-plus
            h2.mb-3.success--text Add a new lead
            p.mb-0 A lead is somebody you want to turn into a customer
          v-card-actions
           v-row(dense justify="space-between" align="center")
            v-col(cols="12")
              v-btn(text tile block color="success" to="/crm/CreateLead")
                v-icon.mr-2 mdi-account-plus
                | Add a new lead


            
    
</template>

<script>
export default {
  name: "AddNewLeadCard",
  components: {},
  computed: {},
  data() {
    return {};
  },
  props: {},
  methods: {},
  mounted() {}
};
</script>
