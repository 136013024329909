<template lang="pug">
    v-dialog(v-model="showEditAgencyBranding" max-width="600")
        v-card.pa-5
            h2.text-center Edit Agency Branding
            v-card.grey.lighten-3.pa-2.my-6(flat)
              p.body-2.mb-0 Warning: making any change here will update ALL proposals across your entire agency, for EVERY agent.
            v-text-field(outlined label="Agency Name", placeholder="Agency Name", v-model="name")
            v-textarea(rows="2" outlined label="Agency Description", placeholder="Description", v-model="description")
            v-row
                v-col(cols="12" md="6")
                    v-text-field(outlined label="Main Website URL", placeholder="https://www.agency-name.com", v-model="url")
                v-col(cols="12" md="6")
                    v-text-field(outlined label="Logo Image URL", placeholder="https://www.agency-name.com/logo.png", v-model="logo")
            v-row
                v-col(cols="12" md="6")
                    v-text-field(outlined label="Main Branding Color", placeholder="#445040", v-model="colors.primary")
                v-col(cols="12" md="6")
                    v-text-field(outlined label="Accent Branding Color", placeholder="#445040", v-model="colors.accent")
            v-card-actions
                v-spacer
                v-btn(:disabled="loading", color="error" plain @click="showEditAgencyBranding = false") Cancel
                v-btn(depressed color="success" :loading="loading" @click="handleClick") Save
</template>

<script>
import { get, sync, call } from "vuex-pathify";
export default {
  name: "AgencyBrandingEdit",
  watch: {
    showEditAgencyBranding() {
      this.loading = false;
    }
  },
  data() {
    return {
      loading: false,
      colors: {
        primary: "",
        accent: ""
      },
      logo: "",
      url: "",
      name: "",
      description: ""
    };
  },
  created() {
    Object.entries(this.branding).forEach(itm => (this[itm[0]] = itm[1]));
  },
  computed: {
    ...get({
      userData: "User/userData",
      branding: "User/agencyData@branding"
    }),
    ...sync({
      showEditAgencyBranding: "CrmNav/dialogs@showEditAgencyBranding"
    })
  },
  methods: {
    ...call({
      upsertBranding: "User/upsertBranding",
      bindAgencyData: "User/bindAgencyData"
    }),
    async handleClick() {
      this.loading = true;
      const { colors, logo, url, name, description } = this;
      await this.upsertBranding({ colors, logo, url, name, description });
      await this.bindAgencyData({ agencyId: this.userData.agencyId });
      this.showEditAgencyBranding = false;
    }
  }
};
</script>
