<template lang="pug">
    .create-lead.pa-12
        v-btn(icon color="grey" x-large to="/crm/Leads")
          v-icon mdi-arrow-left-circle
        v-container(fluid).text-center.mb-12
          .title.mb-3
            .text-h4 Create a new lead
          span You can send property proposals to leads and track when they view properties.
        v-container
          v-row(justify="center")
            v-col(cols="12" md="12" lg="8")
              v-card(outlined color="grey lighten-4" max-width="700").pa-5.mx-auto
                EditBasicInfo(buttonText="Create new lead", isNewLead, onSaveGoBackToLeadsList)
            v-col(cols="12" md="12" lg="4")
              v-img(src="@/assets/crm/add_user.svg" :max-width="$vuetify.breakpoint.mdAndDown? 250 : 500")
</template>

<script>
import { sync } from "vuex-pathify";
import EditBasicInfo from "@/components/CRM/Leads/EditBasicInfo.vue";
import { call } from "vuex-pathify";
export default {
  name: "CreateLead",
  components: { EditBasicInfo },
  data() {
    return {
      loading: false,
      errorMessage: null
    };
  },
  mounted() {
    this.initNewLead();
  },
  computed: {
    ...sync({
      firstName: "Leads/firstName",
      lastName: "Leads/lastName",
      email: "Leads/email",
      phonePrimary: "Leads/phonePrimary"
    })
  },
  methods: {
    ...call({ initNewLead: "Leads/initNewLead" })
  }
};
</script>

<style lang="scss" scoped></style>
