<template lang="pug">
    .counts
        h3 View Counts
        v-data-table(:items="items", :headers="headers" disable-pagination hide-default-footer sortBy="views" sortDesc :hide-default-header="$vuetify.breakpoint.smAndDown")
          template(v-slot:item.views="{ item }")
            .label(v-if="$vuetify.breakpoint.smAndDown") Views
            .font-weight-bold {{item.views}}
          template(v-slot:item.listing="{ item }")
            .label(v-if="$vuetify.breakpoint.smAndDown") Listing
            a(:href="item.url && item.url.length > 2 ? item.url : null" target="_blank" rel="noopener").text-decoration-none
              b {{item.title}}
          template(v-slot:item.url="{ item }")
            .label(v-if="$vuetify.breakpoint.smAndDown") Url
            a(:href="item.url && item.url.length > 2 ? item.url : null" target="_blank" rel="noopener") {{item.url}}
          template(v-slot:item.price="{ item }")
            .label(v-if="$vuetify.breakpoint.smAndDown") Price
            span {{item.price}}
</template>

<script>
import { get } from "vuex-pathify";
export default {
  name: "Counts",
  data() {
    return {
      headers: [
        {
          text: "Views",
          value: "views",
          class: "header",
          width: 100
        },
        {
          text: "Listing",
          value: "listing",
          class: "header",
          width: "auto",
          sortable: false
        },
        {
          text: "URL",
          value: "url",
          class: "header",
          sortable: false,
          width: 50
        },
        {
          text: "Price",
          value: "price",
          class: "header",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...get({
      counts: "Analytics/counts"
    }),
    items() {
      if (!this.counts || this.counts.length < 1) return [];

      return this.counts.map(itm => {
        const { views, url } = itm;
        console.log("processing itm", itm);
        const thisPath = location.href.toLowerCase();

        console.log("thisPath", thisPath);
        console.log("url", url.toLowerCase());

        if (thisPath.includes(url.toLowerCase())) {
          // must be main proposal view
          return {
            views,
            url: "#",
            title: "Overall Proposal",
            price: "-"
          };
        } else {
          return {
            views,
            url,
            title: itm.property ? itm.property.title : "",
            price: itm.property ? itm.property.price : ""
          };
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .header {
  background: rgba(223, 223, 223, 1);
}

.label {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
}
@media (max-width: 600px) {
  ::v-deep .v-data-table__mobile-row__cell {
    text-align: left !important;
  }

  ::v-deep tr.v-data-table__mobile-table-row td:first-child {
    margin-top: 2rem;
    background: rgba(223, 223, 223, 0.5);
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  ::v-deep tr.v-data-table__mobile-table-row td:last-child {
    padding-bottom: 2rem !important;
  }
}
</style>
