<template lang="pug">
    .maker-section--add-intro
      TabCard(:title="title", :subtitle="subtitle", showNextButton, showBackButton, :buttonError="buttonError", @click:next="$emit('click:next')", @click:back="$emit('click:back')")
        v-textarea(rows="4" outlined hide-details :placeholder="placeholder", @change="intro = $event").grey.lighten-3.mt-6
</template>

<script>
import { get, sync } from "vuex-pathify";
import TabCard from "@/components/CRM/Proposals/Maker/TabCard";
export default {
  name: "MakerSectionPasteLinks",
  components: { TabCard },
  data() {
    return {
      title: "Your Introduction",
      subtitle:
        "(optional) You can write a personal introduction about you, your company, and these links you are preparing to share.",
      placeholder:
        "(optional) Hi, this is Mary from ABC Properties. Please review these properties and contact me at +359 12345678 for viewing..."
    };
  },
  computed: {
    ...get({
      buttonError: "ProposalMaker/buttonError"
    }),
    ...sync({ intro: "ProposalMaker/intro" })
  }
};
</script>
